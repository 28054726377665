import { rootApi } from '../rootApi';
import { CandidateProfile } from '../candidates/candidates.types';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  Offer,
  Placement,
  Application,
  CreateOfferDto,
  StageChangeRequest,
  ApplicationInsight,
  GetApplicationsQuery,
  ActionOfferRequestProps,
  StageChangeRequestAction,
  StageChangeRequestStatus,
  ActionResourceRequestProps,
  ApplicationResourceRequest,
  InitiateResourceRequestProps,
} from './applications.types';

export const applicationsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query<PaginatedResponse<Application>, GetApplicationsQuery>({
      query: ({ params }) => ({
        url: `/jobs/applications`,
        params,
      }),
      providesTags: ['Applications'],
    }),

    getJobApplications: builder.query<PaginatedResponse<Application>, GetApplicationsQuery>({
      query: ({ params, jobId }) => ({
        url: `/jobs/${jobId}/applications`,
        params,
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'JobApplications', id: arg.jobId },
        { type: 'Applications' },
      ],
    }),

    getApplicationActivities: builder.query<
      PaginatedResponse<IActivity>,
      { applicationId: string; page: number; perPage: number }
    >({
      query: ({ applicationId, perPage, page }) => ({
        url: `/jobs/applications/${applicationId}/activities`,
        params: {
          page,
          per_page: perPage,
        },
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'ApplicationActivities', id: arg.applicationId },
      ],
    }),

    withdrawApplication: builder.mutation<APIResponse, { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: `/jobs/applications/${applicationId}/withdraw`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'ApplicationActivities', id: arg.applicationId },
        'Applications',
        'ApplicationRequests',
      ],
    }),

    progressCandidateApplication: builder.mutation<
      Application,
      {
        applicationId: string;
        data: { stage: string; rejection_context?: { reason: string; feedback: string } };
      }
    >({
      query: ({ applicationId, data }) => ({
        url: `/jobs/applications/${applicationId}/progress`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Applications', 'ApplicationRequests'],
    }),

    shortlistApplications: builder.mutation<
      APIResponse,
      { jobId: string; applicationIds: string[] }
    >({
      query: ({ jobId, applicationIds }) => ({
        url: `/jobs/${jobId}/applications/shortlist`,
        method: HTTPRequestType.POST,
        data: { application_ids: applicationIds },
      }),
      invalidatesTags: ['Applications', 'ApplicationRequests'],
    }),

    requestRejectApplications: builder.mutation<APIResponse, { applicationIds: string[] }>({
      query: ({ applicationIds }) => ({
        url: `/jobs/applications/request-rejection`,
        method: HTTPRequestType.POST,
        data: { application_ids: applicationIds },
      }),
      invalidatesTags: ['Applications', 'ApplicationRequests'],
    }),

    getApplicationById: builder.query<Application, string>({
      query: (applicationId) => ({
        url: `/jobs/applications/${applicationId}`,
      }),
      providesTags: ['Applications'],
    }),

    requestApplicationStageChange: builder.mutation<
      APIResponse,
      {
        applicationId: string;
        requested_stage: string;
        rejection_reason?: string;
        rejection_feedback?: string;
      }
    >({
      query: ({ applicationId, requested_stage, rejection_reason, rejection_feedback }) => ({
        url: `/jobs/applications/${applicationId}/stage-change-requests`,
        method: HTTPRequestType.POST,
        data: {
          requested_stage,
          rejection_reason,
          rejection_feedback,
        },
      }),
      invalidatesTags: ['Applications', 'ApplicationRequests'],
    }),

    getApplicationStageChangeRequests: builder.query<
      PaginatedResponse<StageChangeRequest>,
      { params: { status?: StageChangeRequestStatus } & PageOptions; applicationId: string }
    >({
      query: ({ params, applicationId }) => ({
        url: `/jobs/applications/${applicationId}/stage-change-requests/incoming`,
        params,
      }),
      providesTags: ['ApplicationRequests'],
    }),

    getApplicationStageChangeRequestById: builder.query<StageChangeRequest, string>({
      query: (requestId) => ({
        url: `/jobs/applications/stage-change-requests/${requestId}`,
      }),
      providesTags: ['ApplicationRequests'],
    }),

    actionStageChangeRequest: builder.mutation<
      APIResponse,
      {
        requestId: string;
        action: StageChangeRequestAction;
        rejection_reason?: string;
        rejection_feedback?: string;
      }
    >({
      query: ({ requestId, action, rejection_feedback, rejection_reason }) => ({
        url: `/jobs/applications/stage-change-requests/${requestId}/action`,
        method: HTTPRequestType.POST,
        data: {
          action,
          rejection_feedback,
          rejection_reason,
        },
      }),
      invalidatesTags: ['ApplicationRequests', 'Applications'],
    }),

    getAllJobStageChangeRequests: builder.query<
      PaginatedResponse<StageChangeRequest>,
      { jobId: string; params: { status?: StageChangeRequestStatus } & PageOptions }
    >({
      query: ({ jobId, params }) => ({
        url: `/jobs/${jobId}/stage-change-requests/incoming`,
        params,
      }),
      providesTags: ['ApplicationRequests'],
    }),

    createOffer: builder.mutation<APIResponse, { applicationId: string; offer: CreateOfferDto }>({
      query: ({ applicationId, offer }) => ({
        url: `/jobs/applications/${applicationId}/offers`,
        method: HTTPRequestType.POST,
        data: offer,
      }),
      invalidatesTags: ['Applications', 'ApplicationRequests', 'Offers'],
    }),

    updateOffer: builder.mutation<APIResponse, { offerId: string; offerData: CreateOfferDto }>({
      query: ({ offerId, offerData }) => ({
        url: `/jobs/applications/offers/${offerId as string}`,
        method: HTTPRequestType.PUT,
        data: offerData,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Offers', id: arg.offerId },
        { type: 'Applications' },
        { type: 'ApplicationRequests' },
        { type: 'JobOffers' },
      ],
    }),

    getApplicationOffers: builder.query<
      PaginatedResponse<Offer>,
      { applicationId: string; params: PageOptions }
    >({
      query: ({ applicationId, params }) => ({
        url: `/jobs/applications/${applicationId}/offers`,
        params,
      }),
      providesTags: ['Offers'],
    }),

    getApplicationCandidateProfile: builder.query<CandidateProfile, string>({
      query: (applicationId) => ({
        url: `/jobs/applications/${applicationId}/candidate-profile`,
      }),
    }),

    getOfferById: builder.query<Offer, { offerId: string }>({
      query: ({ offerId }) => ({
        url: `/jobs/applications/offers/${offerId}`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'Offers', id: arg.offerId }],
    }),

    getMyOffers: builder.query<PaginatedResponse<Offer>, PageOptions>({
      query: (params) => ({
        url: `/jobs/applications/offers`,
        params,
      }),
      providesTags: ['Offers'],
    }),

    getOffersByJobId: builder.query<
      PaginatedResponse<Offer>,
      { jobId: string; params: PageOptions }
    >({
      query: ({ jobId, params }) => ({
        url: `/jobs/${jobId}/applications/offers`,
        params,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'JobOffers', id: arg.jobId }],
    }),

    actionOffer: builder.mutation<Placement | null, ActionOfferRequestProps>({
      query: ({ offerId, action, documents }) => ({
        url: `/jobs/applications/offers/${offerId}/action`,
        method: HTTPRequestType.POST,
        data: {
          action,
          documents
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Offers' },
        { type: 'Offers', id: arg.offerId },
        { type: 'Applications' },
        { type: 'JobOffers' },
      ],
    }),

    approveOffer: builder.mutation<APIResponse, { offerId: string }>({
      query: ({ offerId }) => ({
        url: `/jobs/applications/offers/${offerId}/approve`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Offers', id: arg.offerId },
        { type: 'Offers' },
        { type: 'Applications' },
        { type: 'JobOffers' },
      ],
    }),

    requestOfferChanges: builder.mutation<APIResponse, { offerId: string; notes: string }>({
      query: ({ offerId, notes }) => ({
        url: `/jobs/applications/offers/${offerId}/request-changes`,
        method: HTTPRequestType.POST,
        data: { notes },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Offers', id: arg.offerId },
        { type: 'Offers' },
        { type: 'Applications' },
        { type: 'JobOffers' },
      ],
    }),

    rescindOffer: builder.mutation<APIResponse, { offerId: string }>({
      query: ({ offerId }) => ({
        url: `/jobs/applications/offers/${offerId}/rescind`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Offers', id: arg.offerId },
        { type: 'Offers' },
        { type: 'Applications' },
        { type: 'ApplicationRequests' },
        { type: 'JobOffers' },
      ],
    }),

    // Placements ---------------------------------------------------------

    getJobPlacements: builder.query<
      PaginatedResponse<Placement>,
      { jobId: string; params: PageOptions }
    >({
      query: ({ jobId, params }) => ({
        url: `/jobs/${jobId}/placements`,
        params,
      }),
      providesTags: ['Placements'],
    }),

    addPlacementToProfile: builder.mutation<APIResponse, { placementId: string }>({
      query: ({ placementId }) => ({
        url: `/jobs/placements/${placementId}/add-to-profile`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Placements', 'Profile'],
    }),

    removePlacement: builder.mutation<APIResponse, string>({
      query: (placementId) => ({
        url: `/jobs/placements/${placementId}`,
        method: HTTPRequestType.DELETE,
      }),
      invalidatesTags: ['Placements', 'JobSummary'],
    }),

    // Resource -----------------------------------------------------------
    initiateResourceRequest: builder.mutation<APIResponse, InitiateResourceRequestProps>({
      query: ({ applicationId, data }) => ({
        url: `/jobs/applications/${applicationId}/resource-requests`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['ResourceRequests'],
    }),

    getIncomingApplicationResourceRequests: builder.query<
      PaginatedResponse<ApplicationResourceRequest>,
      { applicationId: string; params: PageOptions }
    >({
      query: ({ applicationId, params }) => ({
        url: `/jobs/applications/${applicationId}/resource-requests/incoming`,
        params,
      }),
      providesTags: ['ResourceRequests'],
    }),

    getOutgoingApplicationResourceRequests: builder.query<
      PaginatedResponse<ApplicationResourceRequest>,
      { applicationId: string; params: PageOptions }
    >({
      query: ({ applicationId, params }) => ({
        url: `/jobs/applications/${applicationId}/resource-requests/outgoing`,
        params,
      }),
      providesTags: ['ResourceRequests'],
    }),

    actionResourceRequest: builder.mutation<APIResponse, ActionResourceRequestProps>({
      query: ({ requestId, data }) => ({
        url: `/jobs/applications/resource-requests/${requestId}/action`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['ResourceRequests'],
    }),

    // insights
    getApplicationInsights: builder.query<ApplicationInsight[], string>({
      query: (applicationId) => ({
        url: `/jobs/applications/${applicationId}/insights`,
      }),
    }),

  }),
});

export const {
  useGetApplicationsQuery,
  useGetJobApplicationsQuery,
  useProgressCandidateApplicationMutation,
  useShortlistApplicationsMutation,
  useWithdrawApplicationMutation,
  useGetApplicationActivitiesQuery,
  useRequestRejectApplicationsMutation,
  useGetApplicationByIdQuery,
  useRequestApplicationStageChangeMutation,
  useGetApplicationStageChangeRequestsQuery,
  useGetApplicationStageChangeRequestByIdQuery,
  useActionStageChangeRequestMutation,
  useGetAllJobStageChangeRequestsQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useGetApplicationOffersQuery,
  useGetOfferByIdQuery,
  useGetMyOffersQuery,
  useActionOfferMutation,
  useGetApplicationCandidateProfileQuery,
  useApproveOfferMutation,
  useRequestOfferChangesMutation,
  useRescindOfferMutation,
  useGetOffersByJobIdQuery,

  useGetJobPlacementsQuery,
  useAddPlacementToProfileMutation,
  useRemovePlacementMutation,

  useInitiateResourceRequestMutation,
  useGetIncomingApplicationResourceRequestsQuery,
  useGetOutgoingApplicationResourceRequestsQuery,
  useActionResourceRequestMutation,

  useGetApplicationInsightsQuery,
} = applicationsApi;
