import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { debounce, isString } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState, useEffect, useCallback, SyntheticEvent } from 'react';

import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Grid, Stack, Checkbox, FormLabel, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { Category } from 'src/services/utils/utils.types';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useLazySearchCategoriesQuery } from 'src/services/utils/utils.service';
import { useUpdateConnectionSettingsRequestMutation } from 'src/services/connections/connections.service';
import {
  Connection,
  UpdateConnectonSettingsPops,
} from 'src/services/connections/connections.types';

import RHFSwitch from 'src/components/hook-form/rhf-switch';
import FormProvider from 'src/components/hook-form/form-provider';
import QuantityInput from 'src/components/number-input/quantity-input';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  connection: Connection;
  onClose: VoidFunction;
};

const generateErrorMessage = (e: any) => i18n.t('admin-connections.api.update.default_error');

// ----------------------------------------------------------------------

export default function EditConnectionModal({ open, connection, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const authContext = useAuthContext();

  const [jobsLimit, setJobsLimit] = useState<number>(1);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchCategories, { currentData: categoriesData }] = useLazySearchCategoriesQuery();

  const [updateConnectionSettings] = useUpdateConnectionSettingsRequestMutation();

  const ActionConnectionSchema = Yup.object().shape({
    // Options
    job_limit_applied: Yup.boolean().default(false),

    jobs_limit: Yup.number().integer(),

    specializations: Yup.array().of(Yup.object()),
  });

  const defaultValues = {
    job_limit_applied: !!connection.job_limit,
    jobs_limit: connection.job_limit ?? 0,
    specializations: [...connection.approved_specializations],
  };

  const methods = useForm({
    resolver: yupResolver(ActionConnectionSchema),
    defaultValues,
    mode: 'all',
  });

  const { reset, handleSubmit, setValue, formState, watch } = methods;

  const { isSubmitting, isValid } = formState;

  const jobLimitApplied = watch('job_limit_applied');

  const onCloseClicked = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const requestData: UpdateConnectonSettingsPops = {
      organization_id: authContext.organization.id,
      connection_id: connection.id,
      settings: {
        job_limit_applied: data.job_limit_applied,
        jobs_limit: data.jobs_limit ?? 0,
        specializations: (data.specializations as Category[]).map((item) => item.id),
      },
    };
    try {
      await updateConnectionSettings(requestData).unwrap();

      enqueueSnackbar(t('admin-connections.api.update.success'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(generateErrorMessage(e), { variant: 'error' });
    }

    reset();
    setJobsLimit(0);
    onClose();
  });

  const debounceSearchCategories = useMemo(
    () =>
      debounce((input: string) => {
        searchCategories({
          name: input,
          page: 1,
          per_page: 5,
        });
      }),
    [searchCategories]
  );

  useEffect(() => {
    if (!searchQuery) return;

    debounceSearchCategories(searchQuery);
  }, [debounceSearchCategories, searchQuery]);

  const onSearch = (_event: SyntheticEvent, value: string) => setSearchQuery(value);

  const onSelectSpecialization = (_event: SyntheticEvent, value: string[]) => {
    if (!value) return;

    setValue('specializations', [...value]);
  };

  const onChangeJobLimit = (event: FocusEvent | PointerEvent | KeyboardEvent, value: number) => {
    event.preventDefault();

    setJobsLimit(value);
    setValue('jobs_limit', value);
  };

  useEffect(() => {
    if (!connection) return;

    setValue('jobs_limit', connection.job_limit ?? 0);
    setValue('job_limit_applied', !!connection.job_limit);
    setValue('specializations', connection.approved_specializations);
    setJobsLimit(connection.job_limit ?? 0);
  }, [connection, setValue]);

  const renderConnectionSettings = () => (
    <>
      <Grid container item xs={6} spacing={0.5}>
        <Grid item xs={12} sx={{ color: 'text.secondary' }}>
          <FormLabel>
            {t('admin-connections.jobs_limit_form.limit')}
          </FormLabel>
        </Grid>
        <Grid item xs={12}>
          <RHFSwitch label={t('admin-connections.jobs_limit_form.limit_label')} name="job_limit_applied" />
        </Grid>
      </Grid>

      <Grid container item xs={6} spacing={1}>
        {
          jobLimitApplied &&
          <>
            <Grid item xs={12} sx={{ color: 'text.secondary' }}>
              <FormLabel>
                {t('admin-connections.jobs_limit_form.count')}
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ typography: 'subtitle2' }}
              flexDirection="row"
              justifyContent="flex-start"
            >
              <QuantityInput
                min={1}
                max={99}
                className="w-100"
                value={jobsLimit}
                onChange={onChangeJobLimit as any}
              />
            </Grid>
          </>
        }

      </Grid>

      <Grid container item spacing={1}>
        <Grid item xs={12} sx={{ color: 'text.secondary' }}>
          <FormLabel>
            {t('admin-connections.jobs_limit_form.specialisations')}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sx={{ typography: 'subtitle2' }}>
          <RHFAutocomplete
            multiple
            fullWidth
            onInputChange={onSearch}
            disableCloseOnSelect
            name="specializations"
            placeholder="Start typing to search specialisations"
            options={categoriesData?.results ?? []}
            onChange={onSelectSpecialization as any}
            filterOptions={(x) => x}
            renderOption={(props, option: Category, { selected }) => (
              <li {...props}>
                <Stack direction="row">
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography variant="body1">{option.name}</Typography>
                </Stack>
              </li>
            )}
            getOptionLabel={(option: Category | string) =>
              isString(option) ? option : option.name
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionKey={(option) => (option as Category).id as string}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseClicked}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('admin-connections.action_modal.title')}</DialogTitle>

        <DialogContent>
          <Stack flexDirection="column">
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignContent="center"
              spacing={2}
              sx={{ py: 2, px: 2 }}
            >
              <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
                {t(`admin-connections.create_modal.settings_alert`)}
              </Alert>

              {renderConnectionSettings()}
            </Grid>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant="outlined" onClick={onCloseClicked} loading={isSubmitting}>
            {t('common.cancel')}
          </LoadingButton>

          <LoadingButton
            key="submit-btn"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid}
          >
            {t('common.submit')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
