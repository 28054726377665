import * as Yup from 'yup';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { m, AnimatePresence } from 'framer-motion';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Link,
  Stack,
  Alert,
  useTheme,
  Typography,
  IconButton,
  InputAdornment,
  FormHelperText,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { emailRegex } from 'src/utils/data/regex';

import i18n from 'src/locales/i18n';
import { logOut } from 'src/auth/utils';
import { useTranslate } from 'src/locales';
import { RegisterInviteDTO } from 'src/services/auth/auth.types';
import { TermsType } from 'src/services/connections/connections.types';
import { useAcceptInviteMutation } from 'src/services/auth/auth.service';
import { Invite, InviteStatus } from 'src/services/organisation/organisation.types';
import { useLazyGetOrgInviteQuery } from 'src/services/organisation/organisation.service';
import {
  useGetTenantTermsQuery,
  RequestTermsOfServiceParams,
} from 'src/services/system/system.service';

import Iconify from 'src/components/iconify';
import { varFade, MotionContainer } from 'src/components/animate';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { TenantType } from 'src/types/enums';

const getRegisterErrorMessage = (e: any) => {
  console.log(e);

  return i18n.t('auth.api.accept_invite.default_error');
};

export default function AcceptInviteView() {
  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const showPassword = useBoolean();

  const [searchParams] = useSearchParams();

  const tenant = useRef<TenantType | null>(TenantType.Recruiter);

  const { enqueueSnackbar } = useSnackbar();

  const [tokenError, setTokenError] = useState<string>('');

  const [inviteData, setInviteData] = useState<Invite | null>(null);

  //   const jobInviteToken = searchParams.get('job-invite');
  const orgInviteToken = searchParams.get('invite_token');

  const [getOrgInvite, { isLoading: loadingOrgInvite }] = useLazyGetOrgInviteQuery();

  const [registerByInvite] = useAcceptInviteMutation();

  const { currentData: termsData } = useGetTenantTermsQuery(
    {
      type: TermsType.TERMS_OF_USE,
      target: tenant.current ?? 'b94db1e5-2c53-4da0-9c72-6f9ed95cb8fe',
      // region: moment.locale(), // TODO: Future region locks
    } as RequestTermsOfServiceParams,
    { skip: !tenant.current }
  );

  const getPageTitle = (tenantStr: string | null): string => {
    switch (tenantStr) {
      case TenantType.Client || TenantType.Recruiter:
        return t('auth.register.organisation.title');
      case TenantType.Candidate:
        return t('auth.register.candidate.title');
      default:
        return t('auth.register.welcome');
    }
  };

  const InviteRegisterSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('auth.validation.email.required'))
      .matches(emailRegex, t('auth.validation.email.valid')),

    first_name: Yup.string().required(t('auth.validation.firstName.required')),

    last_name: Yup.string().required(t('auth.validation.lastName.required')),

    password: Yup.string()
      .min(8, t('auth.validation.password.min'))
      .required(t('auth.validation.password.required')),

    repeat_password: Yup.string()
      .test(
        'password-match',
        t('auth.validation.password.match'),
        (value, context) => context.parent.password === value
      )
      .required('Repeat password is required.'),

    invite_token: Yup.string(),

    recaptcha_token: Yup.string().required(t('validation.required')),
  });

  const defaultAccountValues = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    repeat_password: '',
    invite_token: '',
    recaptcha_token: import.meta.env.VITE_NODE_ENV === 'prod' ? '' : 'test-recaptcha-token',
  };

  const accountFormMethods = useForm({
    resolver: yupResolver(InviteRegisterSchema),
    defaultValues: defaultAccountValues,
    mode: 'all',
  });

  const {
    handleSubmit: handleAccountSubmit,
    formState: { isSubmitting: accountSubmitting, isValid: accountFormIsValid, errors },
    setValue,
  } = accountFormMethods;

  const fetchOrgInviteDetails = useCallback(async () => {
    if (!orgInviteToken) return;

    try {
      const invite: Invite = await getOrgInvite(orgInviteToken).unwrap();
      setInviteData(invite);

      setValue('email', invite.email);

      tenant.current = invite.tenant_type;

      if (invite.status === InviteStatus.Expired) {
        setTokenError(t('auth.accept_invite.token_expired_alert'));
      }
    } catch (e) {
      enqueueSnackbar(t('admin-organization.invites.get.default_error'), { variant: 'error' });
    }
  }, [enqueueSnackbar, getOrgInvite, orgInviteToken, setValue, t]);

  useEffect(() => {
    logOut();
  }, []);

  useEffect(() => {
    if (!orgInviteToken) return;

    setValue('invite_token', orgInviteToken);

    fetchOrgInviteDetails();
  }, [fetchOrgInviteDetails, orgInviteToken, setValue]);

  const onSubmitAccount = handleAccountSubmit(async (data) => {
    if (!termsData?.id || !tenant.current) {
      enqueueSnackbar(t('auth.api.register.metadata_error'), {
        variant: 'error',
      });

      return;
    }

    const body: RegisterInviteDTO = {
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      terms_id: termsData?.id,
      timezone: moment.tz.guess(),
      invite_token: orgInviteToken as string,
      recaptcha_token: data.recaptcha_token,
    };

    try {
      await registerByInvite(body).unwrap();

      enqueueSnackbar(t('auth.api.register.success'), {
        variant: 'success',
      });

      router.push(paths.dashboard.root);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(getRegisterErrorMessage(e), {
        variant: 'error',
      });
    }
  });

  const renderTokenExpiredAlert = () => (
    <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
      <Stack direction="column">
        <Stack>{tokenError}</Stack>
      </Stack>
    </Alert>
  );

  const renderHead = () => (
    <Stack spacing={2} sx={{ position: 'relative' }}>
      <Typography variant="h4" textAlign="center" data-testid="title">
        {getPageTitle(tenant.current)}
      </Typography>
    </Stack>
  );

  const renderAccountForm = () => (
    <FormProvider methods={accountFormMethods} onSubmit={onSubmitAccount}>
      <MotionContainer>
        <m.div
          key="more-form"
          variants={varFade().inUp}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid container spacing={2.5} sx={{ marginTop: theme.spacing(0.5) }}>
            <Grid item xs={12}>
              <RHFTextField
                name="email"
                fullWidth
                label={t('auth.form-labels.email')}
                inputProps={{ 'data-testid': 'email-input' }}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="first_name"
                fullWidth
                inputProps={{ 'data-testid': 'first-name-input' }}
                label={t('auth.form-labels.first-name')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <RHFTextField
                fullWidth
                name="last_name"
                inputProps={{ 'data-testid': 'last-name-input' }}
                label={t('auth.form-labels.last-name')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="password"
                label={t('auth.form-labels.password')}
                type={showPassword.value ? 'text' : 'password'}
                fullWidth
                inputProps={{ 'data-testid': 'password-input' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton tabIndex={-1} onClick={showPassword.onToggle} edge="end">
                        <Iconify
                          icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <RHFTextField
                fullWidth
                name="repeat_password"
                label={t('auth.form-labels.repeat-password')}
                type={showPassword.value ? 'text' : 'password'}
                inputProps={{ 'data-testid': 'repeat-password-input' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton tabIndex={-1} onClick={showPassword.onToggle} edge="end">
                        <Iconify
                          icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="center">
              <Stack direction="column" alignItems="center">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                  onChange={(value) =>
                    setValue('recaptcha_token', value ?? '', { shouldValidate: true })
                  }
                />

                {errors.recaptcha_token && (
                  <FormHelperText error>{errors.recaptcha_token.message}</FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="column" spacing={1} alignItems="center">
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  loading={accountSubmitting}
                  data-testid="submit-account-btn"
                  data-custom={accountFormIsValid ? 'valid' : 'not'}
                >
                  {t('auth.utils.sign-up')}
                </LoadingButton>
                <Box>
                  <Stack
                    width="100%"
                    direction="row"
                    gap={1}
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography variant="body2">{t('auth.utils.no-account')}</Typography>
                    <Link href={paths.auth.login} component={RouterLink} variant="subtitle2">
                      {t('auth.utils.login')}
                    </Link>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </m.div>
      </MotionContainer>
    </FormProvider>
  );

  return (
    <>
      {renderHead()}

      <AnimatePresence>
        {orgInviteToken && loadingOrgInvite ? (
          <m.div key="loading" variants={varFade().inUp}>
            <Stack spacing={3} alignItems="center">
              <CircularProgress className="text-blue-500" />
            </Stack>
          </m.div>
        ) : (
          <m.div key="icon" variants={varFade().inUp}>
            <Box textAlign="center">
              <Iconify
                icon="solar:verified-check-bold"
                className="text-blue-500 drop-shadow-lg"
                sx={{ height: 96, width: 96 }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.background.neutral,
                padding: 3,
                boxShadow: theme.customShadows.z1,
                borderRadius: 2,
              }}
            >
              {/* <Typography variant="h4">{candidateInvite?.job.title}</Typography> */}
              <Typography variant="body1">{t('auth.accept_invite.org_invite_message')}</Typography>
            </Box>
          </m.div>
        )}

        <m.div key="token-alert" variants={varFade().inUp}>
          {tokenError && renderTokenExpiredAlert()}
        </m.div>
      </AnimatePresence>

      {renderAccountForm()}
    </>
  );
}
