import * as Sentry from '@sentry/react';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { GetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';

import { rootApi } from 'src/services/rootApi';
import { accountsSlice } from 'src/store/slices/accounts/accountsSlice';
import { candidateSlice } from 'src/store/slices/candidate/candidateSlice';

import { appSlice } from './slices/app/appSlice';
import { dataSlice } from './slices/data/dataSlice';
import { jobsSlice } from './slices/jobs/jobsSlice';
import { joyrideSlice } from './slices/joyride/joyrideSlice';
import { billingSlice } from './slices/billing/billingSlice';
import { connectionsSlice } from './slices/connections/connectionsSlice';
import { applicationsSlice } from './slices/applications/applicationsSlice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const rootReducer = combineReducers({
  [jobsSlice.reducerPath]: jobsSlice.reducer,
  [connectionsSlice.reducerPath]: connectionsSlice.reducer,
  [dataSlice.reducerPath]: dataSlice.reducer,
  [joyrideSlice.reducerPath]: joyrideSlice.reducer,
  [applicationsSlice.reducerPath]: applicationsSlice.reducer,
  [rootApi.reducerPath]: rootApi.reducer,
  [billingSlice.reducerPath]: billingSlice.reducer,
  [appSlice.reducerPath]: appSlice.reducer,
  [accountsSlice.reducerPath]: accountsSlice.reducer,
  [candidateSlice.reducerPath]: candidateSlice.reducer
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware: GetDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(rootApi.middleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
