import { useMemo } from 'react';
import { Outlet } from 'react-router';
import { Helmet } from 'react-helmet-async';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

export default function GlobalOrganizationsPage() {
  const { t } = useTranslate();

  const { org_id } = useParams();

  const isViewingOrgDetails = useMemo(() => !!org_id, [org_id]);

  return (
    <>
      <Helmet>
        <title> Admin: Organisations</title>
      </Helmet>

      <Container
        maxWidth="lg"
        sx={{
          height: 1,
        }}
      >
        <Stack spacing={2}>
          <CustomBreadcrumbs
            heading="Admin: Organisations"
            links={[
              { name: t('common.dashboard'), href: paths.dashboard.root },
              {
                name: 'Organisations',
                href: isViewingOrgDetails
                  ? paths.dashboard.restricted.organizations.root
                  : undefined,
              },
              ...isViewingOrgDetails ?
                [{
                  name: isViewingOrgDetails ? `${org_id}` : undefined,
                  href: undefined,
                }] : [],
            ]}
          />

          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
