import { JobsResponse } from 'src/services/jobs/jobs.types';

import { rootApi } from '../rootApi';
import { DocumentType } from '../documents/documents.types';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  CandidateProfile,
  ActionInviteProps,
  CancelInviteProps,
  InviteCandidatesDTO,
  GetCandidateJobsProps,
  VerificationActionProps,
  UpdateAvailabilityProps,
  OutgoingCandidateInvite,
  IncomingCandidateInvite,
  RequestVerificationProps,
  InviteCandidatesResponse,
  GetCandidateInvitesProps,
  CandidateProfileDocument,
  UploadProfileDocumentData,
  AvailabilityProfileResponse,
  ExperienceVerificationRequest,
} from './candidates.types';

export const candidatesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query<CandidateProfile, void>({
      query: () => ({
        url: '/candidates/my/profile',
        method: HTTPRequestType.GET,
      }),
      providesTags: ['Profile'],
    }),

    getCandidateProfile: builder.query<CandidateProfile, string>({
      query: (id: string) => ({
        url: `/candidates/profiles/${id}`,
        method: HTTPRequestType.GET,
      }),
      providesTags: ['Profile'],
    }),

    getCandidateProfileByOrganization: builder.query<CandidateProfile, string>({
      query: (id: string) => ({
        url: `/candidates/organizations/${id}/profile`,
        method: HTTPRequestType.GET,
      })
    }),

    setCandidateProfile: builder.mutation<
      APIResponse,
      { id: string; data: Partial<CandidateProfile> }
    >({
      query: ({ id, data }) => ({
        url: `/candidates/profiles/${id}`,
        method: HTTPRequestType.PUT,
        data,
      }),
      onQueryStarted: async (arg, { dispatch }) => {
        if (arg.data.profile_image_id || arg.data.first_name || arg.data.last_name) {
          setTimeout(() => {
            dispatch(rootApi.util.invalidateTags(['Profile', 'Account']));
          }, 10000);
        }
      },
      invalidatesTags: ['Account', 'Profile'],
    }),

    requestExperienceVerification: builder.mutation<APIResponse, RequestVerificationProps>({
      query: ({ work_experience_id, data }: RequestVerificationProps) => ({
        url: `/candidates/work-history/${work_experience_id}/verification/request`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Profile'],
    }),

    getExperienceVerificationRequest: builder.query<ExperienceVerificationRequest, string>({
      query: (work_experience_id: string) => ({
        url: `/candidates/work-history/${work_experience_id}`,
        method: HTTPRequestType.GET,
      }),
    }),

    actionExperienceVerification: builder.mutation<APIResponse, VerificationActionProps>({
      query: ({ work_experience_id, data }: VerificationActionProps) => ({
        url: `/candidates/work-history/${work_experience_id}/verification/action`,
        method: HTTPRequestType.POST,
        data,
      }),
    }),

    // Document endpoints -----------------------------------------------------

    importResume: builder.mutation<APIResponse, { document_id: string }>({
      query: ({ document_id }: { document_id: string }) => ({
        url: '/candidates/features/import-resume',
        method: HTTPRequestType.POST,
        data: { document_id },
      }),
      invalidatesTags: ['Profile'],
    }),

    getProfileDocuments: builder.query<
      PaginatedResponse<CandidateProfileDocument>,
      { profileId: string; params: { search_query?: string; type?: DocumentType } & PageOptions }
    >({
      query: ({ params, profileId }) => ({
        url: `/candidates/profiles/${profileId}/documents`,
        params,
      }),
      providesTags: ['Documents'],
    }),

    attachDocumentToProfile: builder.mutation<
      APIResponse,
      { data: UploadProfileDocumentData; profileId: string }
    >({
      query: ({ data, profileId }) => ({
        url: `/candidates/profiles/${profileId}/documents`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Documents'],
    }),

    updateProfileDocument: builder.mutation<
      APIResponse,
      {
        documentId: string;
        data: Pick<UploadProfileDocumentData, 'expiry_date' | 'visibility_rule'>;
      }
    >({
      query: ({ documentId, data }) => ({
        url: `/candidates/profiles/documents/${documentId}`,
        method: HTTPRequestType.PUT,
        data,
      }),
      invalidatesTags: ['Documents'],
    }),

    // Availability endpoints -------------------------------------------------
    getProfileAvailability: builder.query<AvailabilityProfileResponse, void>({
      query: () => ({
        url: `/candidates/my/availability`,
        method: HTTPRequestType.GET,
      }),
      providesTags: ['ProfileAvailability'],
    }),

    updateAvailability: builder.mutation<APIResponse, UpdateAvailabilityProps>({
      query: (data) => ({
        url: '/candidates/my/availability',
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['ProfileAvailability', 'Profile'],
    }),

    updateAvailabilityAccess: builder.mutation<APIResponse, { subscriber_organization: string; status: boolean }>({
      query: ({ subscriber_organization, status }) => ({
        url: `/candidates/my/availability/access`,
        method: HTTPRequestType.POST,
        data: {
          subscriber_organization,
          status,
        },
      }),
      invalidatesTags: ['Recruiters'],
    }),

    // org candidate invites
    getIncomingOrgCandidateInvites: builder.query<
      PaginatedResponse<IncomingCandidateInvite>,
      GetCandidateInvitesProps
    >({
      query: (data: GetCandidateInvitesProps) => ({
        url: `/my/candidates/invites/incoming`,
        params: {
          page: data.page,
          per_page: data.per_page,
          search_query: data.search_query,
        },
      }),
      providesTags: (data) => ['IncomingCandidateInvites'],
    }),

    getOutgoingOrgCandidateInvites: builder.query<
      PaginatedResponse<OutgoingCandidateInvite>,
      GetCandidateInvitesProps
    >({
      query: (data: GetCandidateInvitesProps) => ({
        url: `/my/candidates/invites/outgoing`,
        params: {
          page: data.page,
          per_page: data.per_page,
          search_query: data.search_query,
        },
      }),
      providesTags: ['OutgoingCandidateInvites'],
    }),

    inviteOrgCandidates: builder.mutation<InviteCandidatesResponse[], InviteCandidatesDTO>({
      query: (data) => ({
        url: `/my/candidates/invites`,
        method: HTTPRequestType.POST,
        data: { invites: data.invites },
      }),
      invalidatesTags: ['OutgoingCandidateInvites'],
    }),

    actionOrgCandidateInvite: builder.mutation<APIResponse, ActionInviteProps>({
      query: (data) => ({
        url: `/my/candidates/invites/${data.inviteId}/action`,
        method: HTTPRequestType.POST,
        data: {
          action: data.action,
        },
      }),
      invalidatesTags: (_res, _err, args) => {
        if (args.action === 'accept') {
          return ['IncomingCandidateInvites', 'Recruiters', 'Clients'];
        }
        return ['IncomingCandidateInvites'];
      },
    }),

    cancelOrgCandidateInvite: builder.mutation<APIResponse, CancelInviteProps>({
      query: (data) => ({
        url: `/my/candidates/invites/${data.inviteId}/cancel`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['OutgoingCandidateInvites'],
    }),

    // jobs
    getCandidateJobs: builder.query<JobsResponse, GetCandidateJobsProps>({
      query: (data: GetCandidateJobsProps) => ({
        url: `/my/candidates/${data?.candidateId}/jobs`,
        params: {
          page: data.page,
          per_page: data.per_page,
          search_query: data.search_query,
        },
      }),
      providesTags: ['CandidateJobs'],
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useGetCandidateProfileByOrganizationQuery,
  useGetCandidateProfileQuery,
  useSetCandidateProfileMutation,
  useRequestExperienceVerificationMutation,
  useGetExperienceVerificationRequestQuery,
  useActionExperienceVerificationMutation,
  useImportResumeMutation,

  useGetProfileDocumentsQuery,
  useAttachDocumentToProfileMutation,
  useUpdateProfileDocumentMutation,

  useUpdateAvailabilityMutation,
  useGetProfileAvailabilityQuery,
  useUpdateAvailabilityAccessMutation,

  useGetIncomingOrgCandidateInvitesQuery,
  useGetOutgoingOrgCandidateInvitesQuery,
  useInviteOrgCandidatesMutation,
  useActionOrgCandidateInviteMutation,
  useCancelOrgCandidateInviteMutation,

  useGetCandidateJobsQuery,
} = candidatesApi;
