import { useSnackbar } from 'notistack';
import { Fragment, useState, useCallback } from 'react';

import List from '@mui/material/List';
import Stack from '@mui/system/Stack';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { IJob, JobStatus } from 'src/services/jobs/jobs.types';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { generateArchiveJobError } from 'src/services/jobs/jobs.utils';
import { hideJobVerificationModal } from 'src/store/slices/jobs/jobsSlice';
import { useApproveJobMutation, useArchiveJobMutation, useGetOrganisationJobsQuery } from 'src/services/jobs/jobs.service';

import Label from 'src/components/label';
import EmptyContent from 'src/components/empty-content';

import JobCard from 'src/sections/jobs/cards/job-card';

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

type Props = {
  open: boolean;
};

export default function JobApprovalModal({ open }: Props) {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const tenantType = useOrgTenant();

  const [modalView, setModalView] = useState<'list' | 'action'>('list');
  const [currentJob, setCurrentJob] = useState<IJob | null>(null);

  const connections = useAppSelector((state) => state.data.connections);

  const onClose = useCallback(() => {
    dispatch(hideJobVerificationModal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);

  const { currentData: pendingApprovalData } = useGetOrganisationJobsQuery({
    ...pageOptions,
    job_status: JobStatus.UNAPPROVED,
  });

  const [approveJob, { isLoading: isApproving }] = useApproveJobMutation();

  const [archiveJob, { isLoading: isArchiving }] = useArchiveJobMutation();

  const handleClose = useCallback(() => {
    onClose();
    setModalView('list');
  }, [onClose]);

  const handleApproveJob = useCallback(
    async (jobId: string) => {
      try {
        await approveJob(jobId).unwrap();

        onClose();

        enqueueSnackbar(t('jobs.api.approve.success'), { variant: 'success' });
      } catch (e) {
        console.error(e);

        enqueueSnackbar(t('jobs.api.approve.default_error'), { variant: 'error' });
      }
    },
    [approveJob, enqueueSnackbar, onClose, t]
  );

  const handleArchiveJob = useCallback(
    async (jobId: string) => {
      try {
        await archiveJob(jobId).unwrap();

        onClose();

        enqueueSnackbar(t('jobs.api.archive.success'), { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(generateArchiveJobError(e, tenantType), { variant: 'error' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [archiveJob, enqueueSnackbar, onClose, t, tenantType]);

  const viewJobDetail = useCallback((job: IJob) => {
    setCurrentJob(job);
    setModalView('action');
  }, []);

  const renderButtons = (request: IJob) => (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        loading={isApproving}
        variant="contained"
        sx={{ mr: 1 }}
        aria-label="manage"
        onClick={() => viewJobDetail(request)}
      >
        {t('common.view')}
      </LoadingButton>
    </Stack>
  );

  const renderJobRequest = (job: IJob, index: number) => {
    const jobTitle = job.title;
    const { departments, assigned_account } = job;

    return (
      <Fragment key={`request-${index}`}>
        <ListItem sx={{ mb: 1 }} secondaryAction={renderButtons(job)}>
          <ListItemText
            secondaryTypographyProps={{
              paddingRight: 14,
            }}
            primary={jobTitle}
            secondary={
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2" fontWeight={400}>
                  {`${assigned_account?.first_name} ${assigned_account?.last_name}`}
                </Typography>
                <Label variant="soft" color="info">
                  {departments?.[0]?.name ?? t('jobs.table.no_department')}
                </Label>
                {!!departments?.length && departments.length > 1 && (
                  <Label variant="soft" color="info">
                    +{departments.length - 1}
                  </Label>
                )}
              </Stack>
            }
          />
        </ListItem>
        <Divider variant="middle" component="li" />
      </Fragment>
    );
  };

  const renderPendingRequests = (
    <Stack direction="column">
      
      <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
        {t(`jobs.approval_modal.alert_${tenantType}`)}
      </Alert>

      {pendingApprovalData?.count ? (
        <List dense>
          {pendingApprovalData?.results?.map((job, index) => renderJobRequest(job, index))}
        </List>
      ) : (
        <EmptyContent
          filled
          title="No Data"
          sx={{
            py: 10,
          }}
        />
      )}

      <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
        <Pagination
          page={pageOptions.page || 1}
          count={Math.ceil((pendingApprovalData?.count || 1) / pageOptions.per_page)}
          onChange={(_e, value) =>
            setPageOptions((prev) => ({
              ...prev,
              page: value + 1,
            }))
          }
        />
      </Stack>
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t(`jobs.approval_modal.title`)}
      </DialogTitle>

      <DialogContent>
        {
          modalView === 'list' &&
          <Box sx={{ width: '100%', typography: 'body1' }}>{renderPendingRequests}</Box>
        }

        {
          modalView === 'action' &&
          <Stack spacing={2}>
            <JobCard job={currentJob as IJob} />

            <Stack direction="column" spacing={1}>
              <LoadingButton
                loading={isApproving}
                fullWidth
                variant="contained"
                color="success"
                onClick={() => handleApproveJob(currentJob?.id as string)}
              >
                {t('common.approve')}
              </LoadingButton>
              <LoadingButton
                loading={isArchiving}
                fullWidth
                variant="outlined"
                color="error"
                onClick={() => handleArchiveJob(currentJob?.id as string)}
              >
                {t('common.archive')}
              </LoadingButton>

            </Stack>
          </Stack>
        }

      </DialogContent>

      <DialogActions>
        {
          modalView === 'action' &&
          <Button onClick={() => setModalView('list')}>{t('common.back')}</Button>
        }

        <Button onClick={handleClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
