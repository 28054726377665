import { useState, useEffect, useCallback } from 'react';

import { useBoolean } from 'src/hooks/use-boolean';

import { localStorageGetItem, localStorageSetItem } from 'src/utils/local-storage';

import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { isOrganisationTenant } from 'src/services/auth/auth.utils';
import { ConnectionStatus } from 'src/services/connections/connections.types';
import { setAvailabilityManagerModalVisibility } from 'src/store/slices/candidate/candidateSlice';
import { useGetIncomingConnectionRequestsQuery } from 'src/services/connections/connections.service';

import ImportJobModal from 'src/sections/jobs/modals/import-job-modal';
import JobApprovalModal from 'src/sections/jobs/modals/job-approval-modal';
import MaintenanceModeOverlay from 'src/sections/app/maintenance-mode-overlay';
import JobLinkActionModal from 'src/sections/jobs/modals/job-link-action-modal';
import FeatureAccessModal from 'src/sections/billing/modals/feature-access-modal';
import RequestJobChangesModal from 'src/sections/jobs/modals/request-job-changes';
import CreateOfferModal from 'src/sections/jobs/applications/modals/create-offer-modal';
import CandidateJobActionModal from 'src/sections/jobs/modals/candidate-job-action-modal';
import CandidateOfferModal from 'src/sections/jobs/applications/modals/candidate-offer-modal';
import CandidateJobRequestsModal from 'src/sections/jobs/modals/candidate-job-requests-modal';
import CandidateInvitesActionModal from 'src/sections/jobs/modals/candidate-invites-action-modal';
import PendingCandidateInviteModal from 'src/sections/jobs/modals/pending-candidate-invite-modal';
import AvailabilityManagmentModal from 'src/sections/profile/modals/availability-management-modal';
import ActionStageChangeModal from 'src/sections/jobs/applications/modals/action-stage-change-modal';
import JobStageChangeRequestsModal from 'src/sections/jobs/applications/modals/job-stage-change-requests-modal';
import ManageConnectionRequestsModal from 'src/sections/admin/connections/modal/manage-connection-requests-modal';
import ConnectionActionModal from 'src/sections/connections/modal/connection-action-modal/connection-action-modal';
import CreateConnectionModal from 'src/sections/connections/modal/create-connection-modal/create-connection-modal';

const DEFAULT_PAGE_REQUESTS = {
  page: 1,
  per_page: 5,
};

export default function ModalContainer() {
  const authContext = useAuthContext();
  const tenantType = useOrgTenant();
  const dispatch = useAppDispatch();

  const [incomingRequestsPage, setIncomingRequestsPage] =
    useState<PageOptions>(DEFAULT_PAGE_REQUESTS);

  const { currentData: incomingRequestsData } = useGetIncomingConnectionRequestsQuery({
    organization_id: authContext.organization.id,
    status: ConnectionStatus.PENDING,
    ...incomingRequestsPage,
  });

  const showIncomingRequestsModal = useBoolean();

  const changeIncomingReqestPage = useCallback(
    (page: number) => {
      setIncomingRequestsPage({
        ...incomingRequestsPage,
        page,
      });
    },
    [incomingRequestsPage]
  );

  const currentJobId = useAppSelector((state) => state.jobs.currentJobId);

  const showConnectionActionModal = useAppSelector(
    (state) => state.connectionsState.showConnectionModal
  );

  const showCreateConnectionModal = useAppSelector(
    (state) => state.connectionsState.showCreateConnectionModal
  );

  const showJobLinkActionModal = useAppSelector((state) => state.jobs.showJobLinkActionModal);

  const showJobVerificationModal = useAppSelector((state) => state.jobs.showJobVerificationModal);

  const showCandidateJobRequestsModal = useAppSelector(
    (state) => state.jobs.showCandidateJobRequestsModal
  );

  const showCandidateInviteActionModal = useAppSelector(
    (state) => state.jobs.showCandidateInviteActionModal
  );

  const showCandidateJobInviteActionModal = useAppSelector(
    (state) => state.jobs.showCandidateJobInviteActionModal
  );

  const showPendingCandidateInvitesModal = useAppSelector(
    (state) => state.jobs.showPendingCandidateInvitesModal
  );

  const showImportJobModal = useAppSelector((state) => state.jobs.showImportJobModal);

  const showActionStageChangeRequest = useAppSelector(
    (state) => state.applications.showActionStageChangeRequest
  );

  const showJobStageChangeRequestsModal = useAppSelector(
    (state) => state.applications.showJobStageChangeRequestsModal
  );

  const showCreateOfferModal = useAppSelector((state) => state.applications.showOfferModal);

  const showCandidateOfferModal = useAppSelector(
    (state) => state.applications.showCandidateOfferModal
  );

  const maintenanceModeState = useAppSelector((state) => state.app.maintenanceMode);

  const showFeatureAccessModal = useAppSelector((state) => state.billing.featureAccessModal.open);

  const showRequestJobChangesModal = useAppSelector(
    (state) => state.jobs.showRequestJobChangesModal
  );

  const showAvailabilityManagerModal = useAppSelector(
    (state) => state.candidate.showAvailabilityManagerModal
  );

  useEffect(() => {
    if (!incomingRequestsData?.count) return;
    const shouldShowModal = localStorageGetItem('show_incoming_requests_modal');

    if (shouldShowModal === 'false') return;

    showIncomingRequestsModal.onTrue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingRequestsData]);

  const onIncomingRequestsModalClose = () => {
    showIncomingRequestsModal.onFalse();
    localStorageSetItem('show_incoming_requests_modal', 'false');
  };

  const handleCloseAvailabilityManagerModal = () => {
    dispatch(setAvailabilityManagerModalVisibility({ status: false }));
  }

  return (
    <>
      {isOrganisationTenant(tenantType) && (
        <ConnectionActionModal open={showConnectionActionModal} />
      )}

      <CreateConnectionModal open={showCreateConnectionModal} />

      <JobLinkActionModal open={showJobLinkActionModal} />

      {isOrganisationTenant(tenantType) && <JobApprovalModal open={showJobVerificationModal} />}

      <CandidateJobRequestsModal open={showCandidateJobRequestsModal} />

      {showCandidateInviteActionModal && (
        <CandidateInvitesActionModal open={showCandidateInviteActionModal} />
      )}

      {showCandidateJobInviteActionModal && (
        <CandidateJobActionModal open={showCandidateJobInviteActionModal} />
      )}

      <PendingCandidateInviteModal
        open={showPendingCandidateInvitesModal}
        scopedJobId={currentJobId}
      />

      <ImportJobModal open={showImportJobModal} />

      <FeatureAccessModal open={showFeatureAccessModal} />

      <ManageConnectionRequestsModal
        open={showIncomingRequestsModal.value}
        onClose={onIncomingRequestsModalClose}
        type="incoming"
        requests={incomingRequestsData}
        page={incomingRequestsPage}
        changePage={changeIncomingReqestPage}
      />

      <ActionStageChangeModal open={showActionStageChangeRequest} />

      <JobStageChangeRequestsModal open={showJobStageChangeRequestsModal} />

      <CreateOfferModal open={showCreateOfferModal} />

      {showCandidateOfferModal && <CandidateOfferModal open={showCandidateOfferModal} />}

      {maintenanceModeState && <MaintenanceModeOverlay />}

      {showRequestJobChangesModal && <RequestJobChangesModal open={showRequestJobChangesModal} />}

      <AvailabilityManagmentModal
        open={showAvailabilityManagerModal}
        onClose={handleCloseAvailabilityManagerModal}
      />
    </>
  );
}
