import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useCallback } from 'react';

import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import Stack from '@mui/system/Stack';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Avatar, Skeleton, IconButton, CardContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useUploadFileMutation } from 'src/services/documents/documents.service';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  CandidateAvailabilityStatus,
} from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import DateDisplay from 'src/components/date/date-display';
import FormProvider from 'src/components/hook-form/form-provider';
import ImageCropper from 'src/components/image-cropper/image-cropper';
import FeatureLockedChip from 'src/components/billing/feature-locked-chip';
import AvailabilityStatusLabel from 'src/components/candidates/availability-status-label';

import { TenantType } from 'src/types/enums';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function ProfileUser({ profile, isLoading, mode }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [uploadFile] = useUploadFileMutation();

  const [photoURL, setPhotoURL] = useState<string>('');

  const [updateProfile] = useSetCandidateProfileMutation();

  const tenantType = useOrgTenant();

  const defaultValues = {
    first_name: '',
    last_name: '',
    profile_image_id: '',
    position_title: '',
  };

  const EditUserDetailsSchema = Yup.object().shape({
    first_name: Yup.string().required(t('validation.required')),
    last_name: Yup.string().required(t('validation.required')),
    position_title: Yup.string().nullable(),
    profile_image_id: Yup.string().nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(EditUserDetailsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          first_name: data.first_name as string,
          last_name: data.last_name as string,
          position_title: data.position_title as string,
          profile_image_id: data.profile_image_id ?? undefined,
          sections: [
            ...(profile?.sections?.map((rule) => ({
              type: rule.section_type,
              visibility_rule: rule.visibility_rule,
            })) ?? []),
          ],
        },
      }).unwrap();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  const onEditPhoto = useCallback(
    (url: string) => {
      setPhotoURL(url);
      setValue('profile_image_id', url);

      onSubmit();
    },
    [onSubmit, setValue]
  );

  useEffect(() => {
    if (!isLoading) {
      setPhotoURL(profile?.profile_image?.public_path ?? '');
      reset(
        {
          first_name: profile?.first_name ?? '',
          last_name: profile?.last_name ?? '',
          position_title: profile?.position_title ?? '',
        },
        { keepDirty: false }
      );
    }
  }, [isLoading, profile, reset, setValue]);

  if (mode === 'view') {
    return (
      <Card sx={{ margin: 'auto', boxShadow: 'unset' }}>
        <CardHeader sx={{ pb: 0 }} />
        <CardContent>
          <Stack direction="row" alignItems="start" justifyContent="space-between">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={profile?.profile_image?.public_path}
                alt={`${profile?.first_name} ${profile?.last_name}`}
                sx={{ width: 80, height: 80, mr: 3 }}
              />

              <Stack direction="column" alignItems="start" justifyContent="center">
                {isLoading ? (
                  <Typography variant="h5">{t('common.loading')}</Typography>
                ) : (
                  <>
                    <Typography variant="h5">
                      {profile?.first_name} {profile?.last_name}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      {!_.isEmpty(profile?.position_title)
                        ? profile?.position_title
                        : t('candidates.table.no_position_title')}
                    </Typography>

                    {[TenantType.Candidate, TenantType.Recruiter].includes(tenantType) && (
                      <>
                        {profile?.availability_status ? (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="start"
                            gap={0.5}
                          >
                            <AvailabilityStatusLabel status={profile?.availability_status} />
                            {profile?.availability_status ===
                              CandidateAvailabilityStatus.AVAILABLE_SOON &&
                              profile?.available_from && (
                                <Stack direction="row" alignItems="center" gap={0.5}>
                                  <Typography variant="caption">
                                    {t('candidates.table.available_on')}
                                  </Typography>
                                  <DateDisplay
                                    variant="caption"
                                    date={profile?.available_from}
                                    format="Do of MMM YYYY"
                                  />
                                </Stack>
                              )}
                          </Stack>
                        ) : (
                          <FeatureLockedChip text={t('candidates.table.availability')} />
                        )}
                      </>
                    )}
                  </>
                )}
              </Stack>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ mr: 1 }} href={`mailto://${profile?.email}`}>
                <Iconify icon="streamline:ai-email-generator-spark" width={25} height={25} />
              </IconButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4}>
        <Card sx={{ pt: 4, pb: 4, px: 3, height: '100%', textAlign: 'center' }}>
          <ImageCropper
            onSubmit={onEditPhoto}
            existingUrl={photoURL}
            showGrid
            warningMessage={t('image_cropper.warning_message')}
          />
        </Card>
      </Grid>
      <Grid xs={12} md={8} spacing={2}>
        <Card sx={{ height: '100%' }}>
          <CardHeader
            title={
              isLoading ? (
                <Skeleton width={200} />
              ) : (
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="start"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">{t('account.profile.details')}</Typography>

                  {!isLoading && (
                    <Stack direction="row" alignItems="center" justifyContent="start" gap={1}>
                      {[TenantType.Candidate, TenantType.Recruiter].includes(tenantType) && (
                        <Stack direction="row" alignItems="center" justifyContent="start" gap={1}>
                          <AvailabilityStatusLabel status={profile?.availability_status} />
                          {profile?.availability_status ===
                            CandidateAvailabilityStatus.AVAILABLE_SOON &&
                            profile?.available_from && (
                              <Stack direction="row" alignItems="center" gap={0.5}>
                                <Typography variant="caption">
                                  {t('candidates.table.available_on')}
                                </Typography>
                                <DateDisplay
                                  variant="caption"
                                  date={profile?.available_from}
                                  format="DD/MM/YYYY"
                                />
                              </Stack>
                            )}
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Stack>
              )
            }
          />
          <CardContent sx={{ pt: 4 }}>
            {isLoading ? (
              <Skeleton variant="rectangular" height={100} />
            ) : (
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid xs={6} sm={6}>
                    <RHFTextField
                      name="first_name"
                      label={t('common.form_labels.first_name')}
                      placeholder={t('common.placeholders.first_name')}
                    />
                  </Grid>

                  <Grid xs={6} sm={6}>
                    <RHFTextField
                      name="last_name"
                      label={t('common.form_labels.last_name')}
                      placeholder={t('common.placeholders.last_name')}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <RHFTextField
                      name="position_title"
                      label={t('common.form_labels.position_title')}
                      placeholder={t('common.placeholders.position_title')}
                    />
                  </Grid>

                  <Grid xs={12} />
                </Grid>

                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty}
                  >
                    {t('common.save')}
                  </LoadingButton>
                </Stack>
              </FormProvider>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
