import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState, useEffect } from 'react';

import { Box } from '@mui/system';
import { Grid, Alert, Select, Button, Dialog, MenuItem, TextField, InputLabel, FormControl, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useTranslate } from 'src/locales';
import { FieldOption, IntegrationFieldMapItem } from 'src/services/integration/integrations.types';

type Props = {
  fieldMapItem: IntegrationFieldMapItem;
  open: boolean;
  onClose: VoidFunction;
  onUpdate: (fieldMapItem: IntegrationFieldMapItem, updatedValueMap: { internal: FieldOption, remote: FieldOption }[]) => void;
};

export default function ValueMapperModal({ fieldMapItem, open, onClose, onUpdate }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const [valueMap, setValueMap] = useState<{ internal: FieldOption, remote: FieldOption }[]>([]);

  useEffect(() => {
    if (fieldMapItem && fieldMapItem.value_mapping) {
      setValueMap(fieldMapItem.value_mapping);
    } else {
      if (!fieldMapItem.internal.values) return;
      const initialValueMap = fieldMapItem.internal.values.map((internalValue: any) => ({
        internal: internalValue,
        remote: '',
      }));
      setValueMap(initialValueMap);
    }
  }, [fieldMapItem]);

  const handleValueChange = (index: number, value: string) => {
    const updatedValueMap = _.cloneDeep(valueMap);
    if (fieldMapItem.remote.metadata?.values) {
      const remoteValue = fieldMapItem.remote.metadata.values.find((item: any) => item.value === value);
      updatedValueMap[index].remote = remoteValue;
    }
    setValueMap(updatedValueMap);
  };

  const isAllValuesMapped = useMemo(() => valueMap.every(item => item.remote && item.remote.value), [valueMap]);

  const handleSave = () => {
    if (isAllValuesMapped) {
      onUpdate(fieldMapItem, valueMap);
      onClose();
    } else {
      enqueueSnackbar('integrations.detail.configuration.field_mapping.value_mapper.map_all_values', { variant: 'error' });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('integrations.detail.configuration.field_mapping.value_mapper.label')}</DialogTitle>
      <DialogContent>
        <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
          {t('integrations.detail.configuration.field_mapping.value_mapper.alert')}
        </Alert>
        <Box sx={{ py: 2 }}>
          <Grid container spacing={2}>
            {valueMap.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={fieldMapItem.internal.name}
                      value={_.startCase(item.internal.value)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={!item.remote || !item.remote.value}>
                      <InputLabel>{fieldMapItem.remote.name}</InputLabel>
                      <Select
                        value={item.remote?.value || ''}
                        onChange={(e) => handleValueChange(index, e.target.value)}
                      >
                        {fieldMapItem.remote.metadata?.values?.map((remoteValue: any) => (
                          <MenuItem key={remoteValue.key} value={remoteValue.value}>
                            {remoteValue.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!isAllValuesMapped}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}