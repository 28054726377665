import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import {
  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
} from 'src/services/organisation/organisation.service';

import FormProvider from 'src/components/hook-form';
import RHFNumberInput from 'src/components/hook-form/rhf-number-input';

import DomainsListView from 'src/sections/admin/organization/domains/view/domains-list-view';

import { TenantType } from 'src/types/enums';

import WorkingHoursComponent from '../components/working-hour-selector';

export default function OrganizationSettingsView() {
  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const authContext = useAuthContext();

  const { currentData: settingsData } = useGetOrganizationSettingsQuery(
    authContext.organization.id
  );

  const [updateSettings] = useUpdateOrganizationSettingsMutation();

  const OrgSettingsSchema = Yup.object().shape({
    stop_gap_hours: Yup.number()
      .min(1, t('validation.min_zero'))
      .required(t('validation.required')),
  });

  const methods = useForm({
    resolver: yupResolver(OrgSettingsSchema),
    defaultValues: {
      stop_gap_hours: 1,
    },
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (settingsData) {
      const stopGapSetting = settingsData.find(
        (setting) => setting.setting_type === 'stop_gap_hours'
      );
      if (stopGapSetting) {
        setValue('stop_gap_hours', stopGapSetting.setting_value as number);
      }
    }
  }, [settingsData, setValue]);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const settingsToUpdate = [];

      if (!settingsData) return;

      const stopGapSetting = settingsData.find(
        (setting) => setting.setting_type === 'stop_gap_hours'
      );
      if (stopGapSetting) {
        settingsToUpdate.push({
          id: stopGapSetting.id,
          value: data.stop_gap_hours,
        });
      }

      if (settingsToUpdate.length === 0) return;

      await updateSettings({
        organizationId: authContext.organization.id,
        settings: settingsToUpdate,
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.update.success'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('admin-organization.settings.update.default_error'), { variant: 'error' });
    }
  });

  return (
    <Stack spacing={2}>
      {tenantType === TenantType.Recruiter && (
        <Card sx={{ py: 2, px: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">{t('admin-organization.settings.title')}</Typography>
          </Box>
          <FormProvider methods={methods}>
            <Stack spacing={2} sx={{ px: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <RHFNumberInput
                    min={1}
                    name="stop_gap_hours"
                    label={t('common.form_labels.stop_gap_hours')}
                    placeholder={t('common.placeholders.stop_gap_hours')}
                    helperText={t('common.help_text.stop_gap_hours')}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <LoadingButton
                  type="submit"
                  onClick={onSubmit}
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  {t('common.update')}
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>
        </Card>
      )}

      {tenantType === TenantType.Recruiter && (
        <Card>
          <WorkingHoursComponent />
        </Card>
      )}

      <Card>
        <Box sx={{ pt: 2, px: 2 }}>
          <Typography variant="h6">{t('admin-organization.domains.title')}</Typography>
        </Box>

        <DomainsListView />
      </Card>
    </Stack>
  );
}
