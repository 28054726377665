import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Alert, MenuItem } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { ContactSource } from 'src/services/contacts/contacts.types';
import { useAddContactMutation } from 'src/services/organisation/organisation.service';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export default function AddContactModal({ open, onClose }: Props) {

  const { t } = useTranslate();

  const router = useRouter();

  const authContext = useAuthContext();

  const [addContact] = useAddContactMutation();

  const AddContactSchema = Yup.object().shape({
    first_name: Yup.string().required(t('validation.required')),
    last_name: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.email_format')).required(t('validation.required')),
    phone: Yup.string().nullable(),
    company: Yup.string().required(t('validation.required')),
    type: Yup.string().required(t('validation.required')),
  });

  const methods = useForm({
    resolver: yupResolver(AddContactSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
      type: ''
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = methods;

  const handleAddContact = handleSubmit(async (data) => {
    try {

      await addContact({
        org_id: authContext.organization?.id as string,
        manual_contact: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone as string,
          company: data.company,
          type: data.type as TenantType,
        },
        source: ContactSource.MANUAL_LINK
      }).unwrap();

      enqueueSnackbar(t('contacts.add_modal.success'), { variant: 'success' });
      onClose();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('contacts.add_modal.default_error'), { variant: 'error' });
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{t('contacts.add_modal.title')}</DialogTitle>

      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleAddContact}>
          <Grid container spacing={2} justifyContent="center" pt={1}>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                {t(`contacts.add_modal.alert`)}
              </Alert>
            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                name="first_name"
                label={t('common.form_labels.first_name')}
                required
                fullWidth
              />

            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                name="last_name"
                label={t('common.form_labels.last_name')}
                required
                fullWidth
              />

            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                name="email"
                label={t('common.form_labels.email')}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                name="phone"
                label={t('common.form_labels.phone')}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                name="company"
                label={t('common.form_labels.company_name')}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <RHFSelect
                name="type"
                label={t('contacts.add_modal.contact_type')}
              >
                <MenuItem value="" disabled>
                  {t('common.none')}
                </MenuItem>
                {
                  Object.values(TenantType).map((value: TenantType) => (
                    <MenuItem key={value} value={value}>
                      {t(`enums.tenant.${value}`)}
                    </MenuItem>
                  ))
                }
              </RHFSelect>
            </Grid>

            <Stack sx={{ py: 2, width: '100%' }} flexDirection="row" justifyContent="flex-end">
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                disabled={!isValid}
              >
                {t('contacts.add')}
              </LoadingButton>
            </Stack>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
