import { yupResolver } from '@hookform/resolvers/yup';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { DownloadOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, IconButton, List, ListItem, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';

import { emailRegex } from 'src/utils/data/regex';
import { isEmail } from 'src/utils/validation';

import InviteCandidateTemplate from 'src/assets/templates/invite_candidates.csv?url';
import { useTranslate } from 'src/locales';
import i18n from 'src/locales/i18n';
import { useInviteOrgCandidatesMutation } from 'src/services/candidates/candidates.service';
import { InviteCandidatesResponse } from 'src/services/candidates/candidates.types';

import CSVReader, { CSVReaderResult } from 'src/components/csv/csv-reader';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

const inviteCandidateErrorMessage = (e: any) => {
  if (isString(e)) {
    if (e.includes('already exists')) {
      return i18n.t('candidates.invites.invite_modal.api.errors.already_exists');
    }
    if (e.includes('company email')) {
      return i18n.t('candidates.invites.invite_modal.api.errors.company_email');
    }
  }

  return i18n.t('candidates.invites.invite_modal.api');
};

export default function InviteCandidatesModal({ open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const [inviteResults, setInviteResults] = useState<InviteCandidatesResponse[]>([]);

  const [view, setView] = useState<"manual" | "importer" | "results">("manual");
  const [errorMsg, setErrorMsg] = useState<string>("");

  // bulk invite api
  const [inviteCandidates] = useInviteOrgCandidatesMutation();

  const InviteCandidatesSchema = Yup.object().shape({
    invites: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .required(t('validation.required'))
            .matches(emailRegex, t('validation.email_format')),
          first_name: Yup.string().required(t('validation.required')),
          last_name: Yup.string().required(t('validation.required')),
        })
      )
      .required(t('validation.required'))
      .min(1, t('validation.array_min', { value: 1 })),
  });

  const defaultValues = {
    invites: [{ email: '', first_name: '', last_name: '' }],
  };

  const methods = useForm({
    resolver: yupResolver(InviteCandidatesSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isValid },
  } = methods;

  const invites = methods.watch('invites');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invites',
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await inviteCandidates(data).unwrap();

      setInviteResults(response);
      switchView('results');

      const allFailed = response.every((result) => result.result === 'error');
      const hasErrors = response.some((result) => result.result === 'error');

      if (hasErrors) {
        enqueueSnackbar(t('candidates.invites.invite_modal.api.partial_success'), {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(t('candidates.invites.invite_modal.api.success'), { variant: 'success' });

        reset();
        handleClose();
      }
    } catch (e) {
      enqueueSnackbar(t('candidates.invites.invite_modal.api.default_error'), { variant: 'error' });
    }
  };

  const handleClose = () => {
    reset();
    setView('manual');
    setInviteResults([]);
    onClose();
  };

  const switchView = useCallback((viewType: 'manual' | 'results' | "importer") => {
    if (['manual', 'importer'].includes(viewType)) {
      reset({ invites: [] });
      setInviteResults([]);
    }

    setView(viewType);
  }, [reset])

  const onChangeTab = useCallback((_event: React.ChangeEvent<{}>, newValue: string) => {
    switchView(newValue as "manual" | "importer");
  }, [switchView]);

  const renderTabs = useCallback(() => (
    <Tabs value={view} onChange={onChangeTab} sx={{ pl: 2, mb: 2 }}>
      <Tab key="manual" value="manual" label={t(`candidates.invites.invite_modal.tabs.manual`)} />
      <Tab key="importer" value="importer" label={t(`candidates.invites.invite_modal.tabs.csv`)} />
    </Tabs>
  ), [view, onChangeTab, t]);

  const onCsvRead = (results: CSVReaderResult) => {
    setErrorMsg('');
    if (results.data.length > 0) {
      const uniqueEmails = new Set<string>();
      const validInvites: { email: string, first_name: string, last_name: string }[] = [];

      results.data
        .filter((row) => row.length === 3 && isEmail(row[0]))
        .forEach((row) => {
          const email = row[0].toLowerCase(); // Normalize email to lowercase
          if (!uniqueEmails.has(email)) {
            uniqueEmails.add(email);
            validInvites.push({
              email,
              first_name: row[1],
              last_name: row[2],
            });
          }
        });

      reset({ invites: validInvites });
      if (validInvites.length === 0) {
        setErrorMsg(t('candidates.invites.invite_modal.csv_error'));
      }
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('candidates.invites.invite_modal.title')}</DialogTitle>

        <DialogContent>
          {view === "manual" && (
            <>
              <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                {t('candidates.invites.invite_modal.alert')}
              </Alert>
              {renderTabs()}

              {fields.length > 0 ? (
                fields.map((field, index) => (
                  <Grid container spacing={2} key={field.id} sx={{ mb: 2, pl: 1 }}>
                    <Grid item xs={12} sm={5}>
                      <RHFTextField
                        name={`invites.${index}.email`}
                        label={t('common.form_labels.email')}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <RHFTextField
                        name={`invites.${index}.first_name`}
                        label={t('common.form_labels.first_name')}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <RHFTextField
                        name={`invites.${index}.last_name`}
                        label={t('common.form_labels.last_name')}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton onClick={() => remove(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {t('candidates.invites.invite_modal.no_invites')}
                  </Typography>
                </Box>
              )}

              <Button
                startIcon={<AddIcon />}
                onClick={() => append({ email: '', first_name: '', last_name: '' })}
                sx={{ mt: 2 }}
              >
                {t('candidates.invites.invite_modal.add')}
              </Button>
            </>
          )}

          {view === "importer" && (
            <>
              <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                {t('candidates.invites.invite_modal.alert')}
              </Alert>
              {renderTabs()}

              <Stack direction="column" sx={{ minHeight: 200, mb: 2 }} gap={2}>
                <CSVReader onRead={onCsvRead} />
                <Stack direction="row" justifyContent="start" alignItems="center">
                  <Button startIcon={<DownloadOutlined />} href={InviteCandidateTemplate} download="Invite Candidates Template.csv">
                    {t('candidates.invites.invite_modal.download_template')}
                  </Button>
                </Stack>
              </Stack>

            </>
          )}

          {view === 'results' && (
            <>
              <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                {t('candidates.invites.invite_modal.results_alert')}
              </Alert>
              <Scrollbar sx={{ maxHeight: 400 }}>
                <List>
                  {inviteResults.map((result, index) => (
                    <ListItem key={index} sx={{ display: 'flex', gap: 2 }}>
                      {result.result === 'success' ? (
                        <Iconify icon="carbon:checkmark-filled" color="green" width={25} />
                      ) : (
                        <Iconify icon="ic:sharp-error" color="red" width={25} />
                      )}
                      <ListItemText
                        primary={result.email}
                        secondary={
                          result.result === 'success'
                            ? t('candidates.invites.invite_modal.invite_success')
                            : result.error || t('candidates.invites.invite_modal.invite_error')
                        }
                        secondaryTypographyProps={{
                          color: result.result === 'success' ? '#1fd643' : '#ff4c4c',
                          className: 'capitalize-first',
                        }}
                        primaryTypographyProps={{ className: 'capitalize-first' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Scrollbar>
            </>
          )}

          {errorMsg && (
            <Alert variant="outlined" severity="error" >
              {errorMsg}
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          {view === 'results' && (
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('common.close')}
              </Button>

              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => switchView('manual')}
              >
                {t('candidates.invites.invite_modal.invite_more')}
              </Button>
            </>
          )}

          {["manual", "importer"].includes(view) && (
            <>
              {
                invites.length > 0 && (
                  <Typography variant="body2" sx={{ color: 'text.secondary', mr: 'auto' }}>
                    {t('candidates.invites.invite_modal.added_count', { count: invites.length })}
                  </Typography>
                )
              }

              <Button variant="outlined" onClick={handleClose}>
                {t('common.cancel')}
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
              >
                {t('candidates.invites.invite_modal.invite')}
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
