import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { debounce, isString } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState, useEffect, useCallback, SyntheticEvent } from 'react';

import { Box } from '@mui/system';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Card, Grid, Stack, Button, Avatar, Divider, Checkbox, useTheme, Typography, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { Category } from 'src/services/utils/utils.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { useLazySearchCategoriesQuery } from 'src/services/utils/utils.service';
import { hideConnectionModal } from 'src/store/slices/connections/connectionsSlice';
import { useMarkAllAsReadMutation } from 'src/services/notifications/notifications.service';
import {
  ConnectionSettings,
  ConnectionTermsType,
  ConnectionActionOption,
} from 'src/services/connections/connections.types';
import {
  useActionConnectionRequestMutation,
  useLazyGetConnectionRequestByIdQuery,
} from 'src/services/connections/connections.service';

import FormProvider from 'src/components/hook-form';
import RHFSwitch from 'src/components/hook-form/rhf-switch';
import QuantityInput from 'src/components/number-input/quantity-input';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';

import ViewTermsModal from 'src/sections/terms/view-terms-modal';

import { TenantType } from 'src/types/enums';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
};

const InfoSection = ({ title, content }: { title?: string, content?: string }) => (
  <Grid item xs={12}>
    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {content}
    </Typography>
  </Grid>
);

export default function ConnectionActionModal({ open }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const tenant = useOrgTenant();

  const authContext = useAuthContext();

  const { t } = useTranslate();

  const termsModalControl = useBoolean();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const requestId = useAppSelector((state) => state.connectionsState.connectionRequestId);
  const requestNotificationId = useAppSelector(
    (state) => state.connectionsState.requestNotificationId
  );

  const [fetchConnectionRequest, { isLoading: requestLoading, currentData: connectionData }] =
    useLazyGetConnectionRequestByIdQuery();

  const [actionConnectionRequest, { isLoading: isRejectingConnection }] =
    useActionConnectionRequestMutation();

  const [markAsRead] = useMarkAllAsReadMutation();

  const [jobsLimit, setJobsLimit] = useState<number>(0);

  const [connectionError, setConnectionError] = useState<any>(null);

  const [termsData, setTermsData] = useState<any[]>([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchCategories, { currentData: categoriesData }] = useLazySearchCategoriesQuery();

  useEffect(() => {
    if (requestId) {
      fetchConnectionRequest({
        organization_id: authContext.organization.id,
        request_id: requestId,
      });
    }
  }, [authContext.organization.id, fetchConnectionRequest, requestId]);

  const ActionConnectionSchema = Yup.object().shape({
    job_limit_applied: Yup.boolean(),
    jobs_limit: Yup.number().positive().integer(),
    specializations: Yup.array().of(Yup.object()),
  });

  const defaultValues = {
    job_limit_applied: false,
    jobs_limit: 0,
    specializations: [],
  };

  const methods = useForm({
    resolver: yupResolver(ActionConnectionSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const jobLimitApplied = watch('job_limit_applied');

  const onChangeJobLimit = (event: FocusEvent | PointerEvent | KeyboardEvent, value: number) => {
    event.preventDefault();

    setJobsLimit(value);
    setValue('jobs_limit', value);
  };

  const onSelectSpecialization = (_event: SyntheticEvent, value: string[]) => {
    if (!value) return;

    setValue('specializations', [...value]);
  };

  const onClose = useCallback(() => {
    setConnectionError(null);
    dispatch(hideConnectionModal());
  }, [dispatch]);

  const debounceSearchCategories = useMemo(
    () =>
      debounce((input: string) => {
        searchCategories({
          name: input,
          page: 1,
          per_page: 5,
        });
      }),
    [searchCategories]
  );

  useEffect(() => {
    if (!searchQuery) return;

    debounceSearchCategories(searchQuery);
  }, [debounceSearchCategories, searchQuery]);

  const onSearch = (_event: SyntheticEvent, value: string) => setSearchQuery(value);

  const onActionConnectionRequest = useCallback(
    async (action: ConnectionActionOption) => {
      const connectionSettings: ConnectionSettings | undefined =
        action === ConnectionActionOption.Accept
          ? {
            job_limit_applied: getValues('job_limit_applied') ?? false,
            jobs_limit: getValues('jobs_limit') ?? 0,
            specializations:
              (getValues('specializations') as Category[]).map((item) => item.id) ?? [],
          }
          : undefined;

      try {
        await actionConnectionRequest({
          connectionId: requestId as string,
          action: {
            action,
            connection_settings: connectionSettings,
          },
        }).unwrap();

        onClose();
        markAsRead({ notifications: [requestNotificationId as string] });
        enqueueSnackbar(t(`admin-connections.api.action.success-${action}`), {
          variant: 'success',
        });
        reset();
      } catch (e) {
        if (e.data) {
          setConnectionError(e.data);
        }

        enqueueSnackbar(t(`admin-connections.api.action.default_error`), { variant: 'error' });
      }
    },
    [
      actionConnectionRequest,
      enqueueSnackbar,
      getValues,
      markAsRead,
      onClose,
      requestId,
      requestNotificationId,
      reset,
      t,
    ]
  );

  const openTermsModal = () => {

    const termConfig: { type: "document" | "usage_term"; id: string; public_path?: string; content?: string }[] = [];
    switch (connectionData?.terms_type) {
      case ConnectionTermsType.Template:
      case ConnectionTermsType.Custom:
        connectionData.documents.forEach((doc) => {
          termConfig.push({
            type: "document",
            id: doc.id,
            public_path: doc.file.public_path
          });
        });

        break;
      case ConnectionTermsType.Terms_Of_Engagement:

        connectionData.requested_terms.forEach((term) => {
          termConfig.push({
            type: "usage_term",
            id: term.id,
            content: term.content
          });
        });

        break;
      default:
        break;
    }

    setTermsData(termConfig);
    termsModalControl.onTrue();
  };

  const closeTermsModal = () => {
    termsModalControl.onFalse();
    setTermsData([]);
  }

  const renderConnectionDetails = () => {
    const requestingTenant =
      tenant === TenantType.Client ? connectionData?.recruiter : connectionData?.client;

    return (
      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <InfoSection
              title={t('admin-connections.action_modal.company_name')}
              content={requestingTenant?.company_name}
            />

            <InfoSection
              title={t('admin-connections.action_modal.company_desc')}
              content={requestingTenant?.company_information}
            />

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  {t('admin-connections.action_modal.terms')}
                </Typography>
                <Button
                  onClick={openTermsModal}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  {t('admin-connections.action_modal.view_terms')}
                </Button>
              </Stack>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {t('admin-connections.action_modal.view_terms_desc')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {t('admin-connections.action_modal.initiated_by')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar
                    alt={connectionData?.initiator_account.first_name}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography variant="body2">
                    {`${connectionData?.initiator_account.first_name} ${connectionData?.initiator_account.last_name}`}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            {connectionData?.settings_data?.job_limit_applied && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t('admin-connections.action_modal.jobs_limit')}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {t('admin-connections.action_modal.jobs_limit_desc')}
                      </Typography>
                    </Stack>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {`${connectionData?.settings_data?.jobs_limit} ${t('common.jobs')}`}
                    </Typography>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>

        <ViewTermsModal
          title={t('documents.terms_modal.terms_of_business')}
          open={termsModalControl.value} onClose={closeTermsModal}
          termsData={termsData}
          termsType={connectionData?.terms_type as ConnectionTermsType}
        />

      </Card>
    );
  };

  const renderClientForm = (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: theme.palette.grey[100],
        borderRadius: 2,
        padding: theme.spacing(2)
      }}
    >
      <Grid xs={12} item>
        <Typography variant="h6">
          {t('admin-connections.action_modal.connection_settings')}
        </Typography>
      </Grid>
      <Grid xs={12} item sx={{ typography: 'subtitle2' }}>
        {t('admin-connections.jobs_limit_form.specialisations')}
        <RHFAutocomplete
          multiple
          fullWidth
          onInputChange={onSearch}
          disableCloseOnSelect
          name="specializations"
          placeholder="Start typing to search specialisations"
          options={categoriesData?.results ?? []}
          onChange={onSelectSpecialization as any}
          filterOptions={(x) => x}
          renderOption={(props, option: Category, { selected }) => (
            <li {...props}>
              <Stack direction="row">
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Typography variant="body1">{option.name}</Typography>
              </Stack>
            </li>
          )}
          getOptionLabel={(option: Category | string) => (isString(option) ? option : option.name)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionKey={(option) => (option as Category).id as string}
        />
      </Grid>

      <Grid
        container
        rowSpacing={1}
        sx={{ px: theme.spacing(1), pt: theme.spacing(1) }}
      >
        <Grid xs={12} item sx={{ typography: 'subtitle2' }}>
          {t('admin-connections.jobs_limit_form.limit')}
        </Grid>
        <Grid xs={12} item sx={{ typography: 'subtitle2' }}>
          <Stack direction="row" alignItems="center" justifyContent="start" gap={3}>
            <Box>
              <RHFSwitch label={t('admin-connections.jobs_limit_form.limit_label')} name="job_limit_applied" />
            </Box>

            {
              jobLimitApplied &&
              <Box>
                <QuantityInput min={1} max={99} value={jobsLimit} onChange={onChangeJobLimit as any} />
              </Box>
            }
          </Stack>
        </Grid>
      </Grid>

    </Grid>
  );

  const renderInfoAlert = () => (
    <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
      {t(`admin-connections.action_modal.alert_${tenant}`)}
    </Alert>
  );

  const renderErrorAlert = () => {
    if (connectionError) {
      let message = ``;

      if (connectionError?.feature_type) {
        message = t(`admin-connections.api.errors.features.${connectionError.feature_type}`);
      }

      if (!message) return null;

      return (
        <Alert variant="outlined" severity="error" sx={{ mb: 3, width: '100%' }}>
          {message}
        </Alert>
      );
    }

    return null;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods}>
        <DialogTitle>{t('admin-connections.action_modal.title')}</DialogTitle>

        <DialogContent>
          {renderInfoAlert()}

          {renderErrorAlert()}

          {renderConnectionDetails()}

          {tenant === TenantType.Client && renderClientForm}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant="outlined"
            onClick={() => onActionConnectionRequest(ConnectionActionOption.Reject)}
            disabled={requestLoading}
            loading={isSubmitting || isRejectingConnection}
          >
            {t('common.decline')}
          </LoadingButton>

          <LoadingButton
            variant="contained"
            loading={isSubmitting || isRejectingConnection}
            onClick={() => onActionConnectionRequest(ConnectionActionOption.Accept)}
            disabled={requestLoading}
          >
            {t('common.accept')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
