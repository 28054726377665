import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { m, AnimatePresence } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Button, useTheme, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { emailRegex } from 'src/utils/data/regex';

import { useTranslate } from 'src/locales';
import { useRequestResetPasswordMutation } from 'src/services/auth/auth.service';

import Iconify from 'src/components/iconify';
import { varFade } from 'src/components/animate';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { CompleteResetComponent } from './complete-reset-component';

enum ResetPasswordStage {
  Initial,
  Complete,
}

export default function PasswordResetView() {
  const router = useRouter();

  const { t } = useTranslate();

  const theme = useTheme();

  const [stage, setStage] = useState<ResetPasswordStage>(ResetPasswordStage.Initial);

  const [requestPasswordReset] = useRequestResetPasswordMutation();

  const { enqueueSnackbar } = useSnackbar();

  const goBack = () => {
    router.back();
  };

  const defaultValues = {
    email: '',
  };

  const passwordResetSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('auth.validation.email.required'))
      .matches(emailRegex, t('auth.validation.email.valid')),
  });

  const requestResetMethods = useForm({
    resolver: yupResolver(passwordResetSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = requestResetMethods;

  const onSubmitEmail = handleSubmit(async (data) => {
    try {
      await requestPasswordReset(data).unwrap();

      setStage(ResetPasswordStage.Complete);
      enqueueSnackbar(t('auth.api.reset_request.success'), { variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t('auth.api.reset_request.error'), { variant: 'error' });
    }
  });

  const renderHead = () => (
    <Stack spacing={2}>
      <Typography data-testid="title" variant="h4">
        {t('auth.reset-password.title')}
      </Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">{t('auth.reset-password.subtitle')}</Typography>
      </Stack>

      <Button
        variant="text"
        component={RouterLink}
        startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        onClick={goBack}
        sx={{ maxWidth: 80 }}
      >
        {t('common.back')}
      </Button>
    </Stack>
  );

  const renderForm = () => (
    <AnimatePresence>
      <m.div key="request-form" variants={varFade().inUp}>
        <FormProvider
          key="request-reset-form"
          methods={requestResetMethods}
          onSubmit={onSubmitEmail}
        >
          <Grid container spacing={2.5} sx={{ marginTop: theme.spacing(0.5) }}>
            <Grid item xs={12}>
              <RHFTextField
                name="email"
                fullWidth
                label={t('auth.form-labels.email')}
                inputProps={{ 'data-testid': 'email-input' }}
                disabled={stage !== ResetPasswordStage.Initial}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              {stage === ResetPasswordStage.Initial && (
                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  data-testid="submit-account-btn"
                  data-custom={isValid ? 'valid' : 'not'}
                  disabled={!isValid}
                >
                  {t('common.submit')}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </FormProvider>
      </m.div>

      {stage === ResetPasswordStage.Complete && (
        <m.div key="change-form" variants={varFade().inUp}>
          <CompleteResetComponent />
        </m.div>
      )}
    </AnimatePresence>
  );

  return (
    <Box padding={2}>
      {renderHead()}

      {renderForm()}
    </Box>
  );
}
