import { rootApi } from '../rootApi';
import { OrgSearchProps } from './search.types';
import { PaginatedResponse } from '../api.types';
import { CompanyProfile } from '../connections/connections.types';

export const searchApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    searchActiveOrganisations: builder.query<PaginatedResponse<CompanyProfile>, OrgSearchProps>({
      query: (data) => ({
        url: `/global/${data.tenant}?search_query=${data.searchQuery}&page=${data.page}&per_page=${data.per_page}`,
      }),
    }),
  }),
});

export const { useLazySearchActiveOrganisationsQuery, useSearchActiveOrganisationsQuery } =
  searchApi;
