import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { Connection } from 'src/services/connections/connections.types';
import { User, Department } from 'src/services/organisation/organisation.types';

export interface IUsersState {
  users: User[];
  connections: Connection[];
  departments?: Department[];
}

const initialState: IUsersState = {
  users: [],
  connections: [],
  departments: [],
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setOrgUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setOrgConnections: (state, action: PayloadAction<Connection[]>) => {
      state.connections = action.payload;
    },
    setOrgDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload;
    },
  },
});

export const { setOrgUsers, setOrgConnections, setOrgDepartments } = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
