import { lazy, Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, Navigate } from 'react-router-dom';

import DataFetcher from 'src/utils/data-fetcher';

import { UsageGuard } from 'src/usage';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import SentryErrorPage from 'src/pages/SentryErrorPage';
import CandidatesPage from 'src/pages/candidates/candidates-page';
import ContactsPage from 'src/pages/dashboard/contacts/contacts-page';
import OrganizationDocumentsPage from 'src/pages/dashboard/admin/documents/documents-page';
import OrganizationIntegrationsPage from 'src/pages/dashboard/admin/integrations/integrations-list-page';

import { LoadingScreen } from 'src/components/loading-screen';
import JoyrideContainer from 'src/components/joyride/joyride-container';

import ContactsView from 'src/sections/contacts/view/contacts-view';
import { FileManagerView } from 'src/sections/admin/documents/view';
import CandidatesView from 'src/sections/candidates/view/candidate-list-view';
import CandidateDetail from 'src/sections/candidates/detail/candidate-detail';
import IntegrationsContainer from 'src/sections/admin/integrations/integrations-container';
import IntegrationsListView from 'src/sections/admin/integrations/view/integrations-list-view';
import ApplicationDetailView from 'src/sections/jobs/applications/detail/application-detail-view';
import OrganizationSettingsView from 'src/sections/admin/organization/settings/view/settings-view';
import IntegrationSettingsView from 'src/sections/admin/integrations/view/integrations-settings-view';
import IntegrationActivityView from 'src/sections/admin/integrations/view/integrations-activity-view';

import { paths } from '../paths';

// ----------------------------------------------------------------------

const OrganisationPage = lazy(() => import('src/pages/dashboard/organisation-page'));
const DepartmentsPage = lazy(
  () => import('src/pages/dashboard/admin/organization/department/departments-page')
);
const UsersPage = lazy(() => import('src/pages/dashboard/admin/organization/users/users-page'));
const CompanyProfilePage = lazy(
  () => import('src/pages/dashboard/admin/organization/profile/company-profile-page')
);
const BillingPage = lazy(() => import('src/pages/dashboard/admin/billing/billing-page'));
const ConnectionsPage = lazy(() => import('src/pages/dashboard/connections/connections-page'));
const DepartmentsDetailView = lazy(
  () => import('src/sections/admin/organization/departments/view/departments-detail-view')
);
const AdminConnectionsPage = lazy(
  () => import('src/pages/dashboard/admin/connection-management/connections-management-page')
);
const AdminConnectionsView = lazy(
  () => import('src/sections/admin/connections/view/connections-view')
);
const AdminConnectionsDetailView = lazy(
  () => import('src/sections/admin/connections/view/connection-detail-view')
);
const JobsPage = lazy(() => import('src/pages/dashboard/jobs/jobs-page'));
const CreateJobView = lazy(() => import('src/sections/jobs/create/create-job-view'));
const JobsView = lazy(() => import('src/sections/jobs/view/jobs-view'));
const JobsDetailView = lazy(() => import('src/sections/jobs/detail/job-details-view'));
const CandidateProfilePage = lazy(
  () => import('src/pages/dashboard/profile/candidate-profile-page')
);
const CandidateProfileView = lazy(() => import('src/sections/profile/view/candidate-profile-view'));
const ConnectionsView = lazy(() => import('src/sections/connections/view/connections-view'));
const MyConnectionsDetailView = lazy(
  () => import('src/sections/connections/detail/connections-detail-view')
);

const ActivityPage = lazy(() => import('src/pages/dashboard/activity/activity-page'));
const ActivityView = lazy(() => import('src/sections/activity/view/activity-view'));

const AccountPage = lazy(() => import('src/pages/dashboard/account/account-page'));

const AccountGeneral = lazy(() => import('src/sections/account/account-general'));
const AccountNotifications = lazy(() => import('src/sections/account/account-notifications'));
const AccountChangePassword = lazy(() => import('src/sections/account/account-security'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <ErrorBoundary fallback={<SentryErrorPage />}>
        <AuthGuard>
          <UsageGuard>
            <JoyrideContainer />
            <DataFetcher />
            <DashboardLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </UsageGuard>
        </AuthGuard>
      </ErrorBoundary>
    ),
    children: [
      {
        element: <ActivityPage />,
        children: [
          {
            element: <Navigate to={paths.dashboard.root} replace />,
            index: true,
          },
          {
            path: 'activity',
            element: <ActivityView />,
          },
        ],
      },
      {
        path: 'jobs',
        element: <JobsPage />,
        children: [
          {
            index: true,
            path: '',
            element: <JobsView />,
          },
          {
            path: 'create',
            element: <CreateJobView />,
          },
          {
            path: ':job_id',
            element: <JobsDetailView />,
          },
          {
            path: ':job_id/editor',
            element: <CreateJobView />,
          },
          {
            path: ':job_id/applications/:application_id',
            element: <ApplicationDetailView />,
          },
        ],
      },
      {
        path: 'profile',
        element: <CandidateProfilePage />,
        children: [
          {
            index: true,
            element: <CandidateProfileView />,
          },
        ],
      },
      {
        path: 'account',
        element: <AccountPage />,
        children: [
          {
            path: '',
            index: true,
            element: <AccountGeneral />,
          },
          {
            path: 'notifications',
            element: <AccountNotifications />,
          },
          {
            path: 'security',
            element: <AccountChangePassword />,
          },
        ],
      },
      {
        path: 'connections/:target_tenant',
        element: <ConnectionsPage />,
        children: [
          {
            index: true,
            path: '',
            element: <ConnectionsView />,
          },
          {
            path: ':connection_id',
            element: <MyConnectionsDetailView />,
          },
        ],
      },
      {
        path: 'contacts',
        element: <ContactsPage />,
        children: [
          {
            index: true,
            path: '',
            element: <ContactsView />,
          },
        ],
      },
      {
        path: 'candidates',
        element: <CandidatesPage />,
        children: [
          {
            index: true,
            path: '',
            element: <CandidatesView />,
          },
          {
            path: ':candidate_id',
            element: <CandidateDetail />,
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'organisation',
            element: <OrganisationPage />,
            children: [
              {
                element: (
                  <Navigate to={paths.dashboard.admin.organization.company_profile} replace />
                ),
                index: true,
              },
              {
                path: 'company-profile',
                element: <CompanyProfilePage />,
              },
              {
                path: 'departments',
                element: <DepartmentsPage />,
              },
              {
                path: 'users',
                element: <UsersPage />,
              },
              {
                path: 'departments/:department_id',
                element: <DepartmentsDetailView />,
              },
              {
                path: 'settings',
                element: <OrganizationSettingsView />,
              },
            ],
          },
          {
            path: 'billing',
            element: <BillingPage />,
          },
          {
            path: 'connections',
            element: <AdminConnectionsPage />,
            children: [
              {
                index: true,
                path: '',
                element: <AdminConnectionsView />,
              },
              {
                path: ':connection_id',
                element: <AdminConnectionsDetailView />,
              },
            ],
          },
          {
            path: 'documents',
            element: <OrganizationDocumentsPage />,
            children: [
              {
                index: true,
                path: '',
                element: <FileManagerView />,
              },
            ],
          },
          {
            path: 'integrations',
            element: <OrganizationIntegrationsPage />,
            children: [
              {
                index: true,
                path: '',
                element: <IntegrationsListView />,
              },
            ],
          },
          {
            path: 'integrations/:integration_id',
            element: <IntegrationsContainer />,
            children: [
              {
                index: true,
                path: 'configuration',
                element: <IntegrationSettingsView />,
              },
              {
                index: true,
                path: 'activity',
                element: <IntegrationActivityView />,
              },
            ],
          },
        ],
      },
    ],
  },
];
