import { useState } from 'react';

import { Stack, Popover, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { Category } from 'src/services/utils/utils.types';

import Label from '../label';

type Props = {
  categories?: Category[];
  length?: number;
};

export default function CategoryDisplay({ categories, length = 1 }: Props) {
  const { t } = useTranslate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {categories?.length
        ? categories?.slice(0, length)?.map((item) => (
            <Label variant="soft" color="info" key={`specialisation-${item.id}`} sx={{ mx: 0.5 }}>
              <Typography
                variant="subtitle2"
                fontSize={11}
                fontWeight="700"
                sx={{ textTransform: 'uppercase' }}
              >
                {item.name}
              </Typography>
            </Label>
          ))
        : 'N/A'}

      {categories && categories?.length > length && (
        <Label variant="soft" color="info" sx={{ mx: 0.5 }}>
          <Typography
            variant="subtitle2"
            fontSize={11}
            fontWeight="700"
            sx={{ textTransform: 'uppercase' }}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {`+${categories.length - length} ${t('common.more')}...`}
          </Typography>
        </Label>
      )}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack flexDirection="row" flexWrap="wrap" sx={{ maxWidth: 320, p: 1 }}>
          {categories?.slice(length - 1, -1)?.map((item) => (
            <Label variant="soft" color="info" key={`specialisation-${item.id}`} sx={{ m: 0.5 }}>
              <Typography
                variant="subtitle2"
                fontSize={11}
                fontWeight="700"
                sx={{ textTransform: 'uppercase' }}
              >
                {item.name}
              </Typography>
            </Label>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
