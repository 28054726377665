import * as Yup from 'yup';
import { useState } from "react";
import { isString } from 'lodash';
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import VerificationInput from 'react-verification-input';

import { LoadingButton } from "@mui/lab";
import { Grid, Stack, FormLabel, IconButton, InputAdornment } from "@mui/material";

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from "src/hooks/use-boolean";

import i18n from 'src/locales/i18n';
import { useTranslate } from "src/locales";
import { VERIFICATION_CODE_LENGTH } from 'src/config-global';
import { useCompletePasswordResetMutation } from "src/services/auth/auth.service";

import Iconify from "src/components/iconify";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";

const generateErrorMessage = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === '9_0002') {
      return i18n.t('auth.api.reset_request.invalid');
    }
  }
  return i18n.t('auth.api.reset_request.default_complete_error');
}

export function CompleteResetComponent() {

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const showPassword = useBoolean();

  const router = useRouter();

  const [completeReset] = useCompletePasswordResetMutation();

  const [validationCodeValue, setValidationCodeValue] = useState<string>('');

  const completeResetSchema = Yup.object().shape({
    code: Yup.string()
      .required(t('auth.validation.code.required'))
      .length(7, t('auth.validation.code.length')),
    password: Yup.string()
      .min(8, t('auth.validation.password.min'))
      .required(t('auth.validation.password.required')),
    repeatPassword: Yup.string()
      .test(
        'password-match',
        t('auth.validation.password.match'),
        (value, context) => context.parent.password === value
      )
      .required('Repeat password is required.'),
  });

  const onUpdateCodeValue = (value: string) => {
    setValidationCodeValue(value);
    setValue('code', value, { shouldValidate: true })
  }

  const completeResetMethods = useForm({
    resolver: yupResolver(completeResetSchema),
    defaultValues: {
      password: '',
      repeatPassword: '',
      code: '',
    },
    mode: 'all'
  });

  const {
    handleSubmit,
    formState: {
      isSubmitting,
      isValid,
    },
    setValue,
  } = completeResetMethods;

  const onSubmit = handleSubmit(async (values) => {
    const { code, password } = values
    const completeResetData = {
      code,
      password
    }

    try {
      await completeReset(completeResetData).unwrap();

      enqueueSnackbar(t('auth.api.reset_request.reset_success'), { variant: 'success' });
      router.push(paths.auth.login);
    } catch (e) {
      enqueueSnackbar(generateErrorMessage(e), { variant: 'error' });
    }
  })

  return (
    <FormProvider methods={completeResetMethods} onSubmit={onSubmit}>
      <Stack spacing={2} sx={{ position: 'relative' }}>
        <Stack direction="column" spacing={2}>
          <Stack>
            <FormLabel htmlFor="code">{t('auth.form-labels.reset_code')}</FormLabel>
          </Stack>
          <Stack>
            <VerificationInput
              value={validationCodeValue}
              onChange={onUpdateCodeValue}
              length={VERIFICATION_CODE_LENGTH}
              validChars="0-9"
              autoFocus
              classNames={{
                character:
                  "border-gray-300 rounded-md bg-slate-50 text-gray-500",
                characterSelected: "border-blue-500",
              }}
              inputProps={{
                id: 'verify-code-input'
              }}
            />
          </Stack>
        </Stack>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="password"
            label={t('auth.form-labels.password')}
            type={showPassword.value ? 'text' : 'password'}
            fullWidth
            inputProps={{ 'data-testid': 'password-input' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={showPassword.onToggle} edge="end">
                    <Iconify
                      icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            fullWidth
            name="repeatPassword"
            label={t('auth.form-labels.repeat-password')}
            type={showPassword.value ? 'text' : 'password'}
            inputProps={{ 'data-testid': 'repeat-password-input' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={showPassword.onToggle} edge="end">
                    <Iconify
                      icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Stack direction='row' alignContent="flex-end">
          <LoadingButton
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            data-testid="submit-verify-btn"
            disabled={!isValid}
          >
            {t('common.submit')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  )
}
