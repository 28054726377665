import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { authApi } from 'src/services/auth/auth.service';
import { AuthContext } from 'src/services/auth/auth.types';

import SvgColor from 'src/components/svg-color';


// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {

};

// ----------------------------------------------------------------------

export function useNavData() {
  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { currentData: authData } = authResult;
  const { account, user } = authData as AuthContext;

  const { t } = useTranslate();

  const data = useMemo(
    () => [
      {
        subheader: 'Admin Console',
        items: [
          {
            title: 'Settings',
            path: paths.dashboard.restricted.settings.root,
          },
          {
            title: 'Organizations',
            path: paths.dashboard.restricted.organizations.root,
          },
          {
            title: 'Accounts',
            path: paths.dashboard.restricted.accounts.root,
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return data;
}
