import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { debounce, isString } from 'lodash';

import Box from '@mui/system/Box';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/system/Unstable_Grid/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Stack,
  Avatar,
  Button,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  MobileStepper,
  CircularProgress
} from '@mui/material';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { hideJobLinkActionModal } from 'src/store/slices/jobs/jobsSlice';
import { generateJobLinkRequestError } from 'src/services/jobs/jobs.utils';
import { useMarkAllAsReadMutation } from 'src/services/notifications/notifications.service';
import {
  IJob,
  JobLinkAction,
  LinkBehaviour,
  JobVerificationStatus
} from 'src/services/jobs/jobs.types';
import {
  useGetOrganisationJobsQuery,
  useGetJobLinkRequestByIdQuery,
  useActionJobLinkRequestMutation,
} from 'src/services/jobs/jobs.service';

import SelectableCard from 'src/components/selectable-card/selectable-card';

import JobCard from 'src/sections/jobs/cards/job-card';

import { TenantType } from 'src/types/enums';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  open: boolean;
};

const generateJobLinkActionError = (e: any): string => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === '1_3002') {
      return i18n.t('jobs.invite_modal.api.accept.error.invalid');
    }

    if (e?.data.error_code === '2_0002') {
      return i18n.t('jobs.invite_modal.api.accept.error.exists');
    }
  }

  return i18n.t('jobs.invite_modal.api.accept.error.default');
};

export default function JobLinkActionModal({ open }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [errorMsg, setErrorMsg] = useState<string>('');

  const [linkBehaviour, setLinkBehaviour] = useState<LinkBehaviour>(
    tenant === TenantType.Recruiter ? LinkBehaviour.CLONE_SOURCE : LinkBehaviour.LINK_EXISTING
  );

  const [markAsRead] = useMarkAllAsReadMutation();

  const [selectedJob, onSelectJob] = useState<IJob>();

  const requestId = useAppSelector((state) => state.jobs.jobLinkRequestId as string);
  const jobLinkRequestNotificationId = useAppSelector(
    (state) => state.jobs.jobLinkRequestNotificationId
  );

  const dispatch = useAppDispatch();

  const [jobSearchQuery, setJobSearchQuery] = useState('');

  const { currentData: jobsData } = useGetOrganisationJobsQuery({
    search_query: jobSearchQuery,
    page: 1,
    per_page: 5,
    verification_status:
      tenant === TenantType.Recruiter ? JobVerificationStatus.UNVERIFIED : undefined,
  });

  const { currentData: requestData, isFetching } = useGetJobLinkRequestByIdQuery(requestId, {
    skip: !requestId,
  });

  const [actionRequest, { isLoading: isSubmitting }] = useActionJobLinkRequestMutation();

  const onClose = () => {
    setCurrentPage(0);
    dispatch(hideJobLinkActionModal());
  };

  const onNextPage = () => {
    setCurrentPage(1);
  };

  const onRejectRequest = async () => {
    setErrorMsg('');
    try {
      await actionRequest({
        requestId,
        action: {
          action: JobLinkAction.REJECT,
        },
      }).unwrap();

      if (jobLinkRequestNotificationId)
        markAsRead({ notifications: [jobLinkRequestNotificationId] });

      onClose();
    } catch (e) {
      const errorText = generateJobLinkRequestError(e);
      setErrorMsg(errorText);

      enqueueSnackbar(t('jobs.invite_modal.api.accept.error.default'), { variant: 'error' });
    }
  };

  const onAcceptRequest = async () => {
    setErrorMsg('');
    try {
      await actionRequest({
        requestId,
        action: {
          action: JobLinkAction.ACCEPT,
          options: {
            job_id:
              selectedJob && linkBehaviour === LinkBehaviour.LINK_EXISTING
                ? selectedJob.id
                : undefined,
            link_behaviour: linkBehaviour,
          },
        },
      }).unwrap();

      enqueueSnackbar(
        t('jobs.action.api.success', {
          client: requestData?.source_organization.company_name as string,
        }),
        { variant: 'success' }
      );

      if (jobLinkRequestNotificationId)
        markAsRead({ notifications: [jobLinkRequestNotificationId] });

      onClose();
    } catch (e) {
      const errorText = generateJobLinkRequestError(e);
      setErrorMsg(errorText);

      enqueueSnackbar(t('jobs.invite_modal.api.accept.error.default'), { variant: 'error' });
    }
  };

  const onSearchJobs = debounce((input: string) => {
    setJobSearchQuery(input);
  }, 500);

  const renderRequest = (
    <Stack direction="column" gap={2}>
      <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
        {t(`jobs.action.alert_${tenant}`, {
          company: requestData?.source_organization?.company_name,
        })}
      </Alert>

      <Grid container rowGap={2}>
        <Grid xs={11} sx={{ px: 2 }} display="flex" flexDirection="row" justifyContent="flex-start">
          <Stack direction="column" justifyContent="center" sx={{ mr: 2 }}>
            <Avatar src={requestData?.source_organization?.company_logo?.public_path} />
          </Stack>
          <Stack>
            <Typography variant="h6">{t('jobs.action.info.company_name')}</Typography>
            <Typography>{requestData?.source_organization.company_name}</Typography>
          </Stack>
        </Grid>

        <JobCard job={requestData?.source_job} />

        <Grid xs={12} sm={6} sx={{ px: 2, pt: 2 }}>
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            variant="contained"
            color="error"
            onClick={onRejectRequest}
          >
            {t('common.deny')}
          </LoadingButton>
        </Grid>
        <Grid xs={12} sm={6} sx={{ px: 2, pt: 2 }}>
          <LoadingButton onClick={onNextPage} loading={isSubmitting} fullWidth variant="contained">
            {t('common.accept')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Stack>
  );

  const renderCloner = (
    <Stack direction="column">
      <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
        {t(`jobs.action.alert_clone_${tenant}`)}
      </Alert>

      <Grid container gap={2}>
        {tenant === TenantType.Recruiter ? (
          <>
            <Grid xs={12}>
              <SelectableCard
                title={t('jobs.action.select_job.clone')}
                subtitle={t('jobs.action.select_job.clone_info')}
                selected={linkBehaviour === LinkBehaviour.CLONE_SOURCE}
                onClick={() => setLinkBehaviour(LinkBehaviour.CLONE_SOURCE)}
                flexDirection="row"
              />
            </Grid>
            <Grid xs={12}>
              <SelectableCard
                title={t('jobs.action.select_job.select')}
                subtitle={t('jobs.action.select_job.select_info')}
                selected={linkBehaviour === LinkBehaviour.LINK_EXISTING}
                onClick={() => setLinkBehaviour(LinkBehaviour.LINK_EXISTING)}
                flexDirection={linkBehaviour === LinkBehaviour.LINK_EXISTING ? 'column' : 'row'}
              >
                {linkBehaviour === LinkBehaviour.LINK_EXISTING && (
                  <Stack sx={{ pt: 2 }}>
                    <Autocomplete
                      id="job-select"
                      onChange={(_e: any, value) => value && onSelectJob(value)}
                      options={jobsData?.results ?? []}
                      isOptionEqualToValue={(option: IJob, value: IJob) => option.id === value.id}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            disabled={
                              option.verification_status !== JobVerificationStatus.UNVERIFIED
                            }
                          />
                          {option.title}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label={t('common.jobs')} placeholder="Favorites" />
                      )}
                      onInputChange={(_e: any, value: string) => onSearchJobs(value)}
                    />
                  </Stack>
                )}
              </SelectableCard>
            </Grid>
          </>
        ) : (
          <Grid xs={12} sx={{ pt: 0, width: '100%' }}>
            <Autocomplete
              sx={{ width: '100%' }}
              id="job-select"
              onChange={(_e: any, value) => value && onSelectJob(value)}
              options={jobsData?.results ?? []}
              isOptionEqualToValue={(option: IJob, value: IJob) => option.id === value.id}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={`${t('common.select')} ${t('common.job')}`}
                  placeholder="Favorites"
                />
              )}
              onInputChange={(_e: any, value: string) => onSearchJobs(value)}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );

  const renderCurrentPage = () => {
    if (currentPage === 0) {
      return renderRequest;
    }

    return renderCloner;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{t(`jobs.action.title`)}</DialogTitle>

      <DialogContent>
        {isFetching ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          renderCurrentPage()
        )}

        {errorMsg && (
          <Stack sx={{ mt: 2 }}>
            <Alert severity="error">{errorMsg}</Alert>
          </Stack>
        )}

        <Stack paddingTop={2}>
          <MobileStepper
            steps={2}
            position="static"
            activeStep={currentPage}
            nextButton={undefined}
            backButton={undefined}
            sx={{ justifyContent: 'center' }}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        {currentPage === 1 && (
          <LoadingButton onClick={onAcceptRequest} variant="contained">
            {t('jobs.action.link_job')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
