import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createSelector } from '@reduxjs/toolkit';

export interface IOnlineAccount { user_id: string, id: string, organization_id: string };

export interface IAccountsState {
  online_accounts: IOnlineAccount[];
}

const initialState: IAccountsState = {
  online_accounts: []
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setOnlineAccounts: (state, action: PayloadAction<IOnlineAccount[]>) => {
      state.online_accounts = action.payload;
    },
    addOnlineAccount: (state, action: PayloadAction<IOnlineAccount>) => {
      state.online_accounts.push(action.payload);
    },
    removeOnlineAccount: (state, action: PayloadAction<IOnlineAccount>) => {
      state.online_accounts = state.online_accounts.filter(account => account.id !== action.payload.id);
    }

  },
});

// check if a given account is online
export const isAccountOnline = createSelector(
  [
    (state: any) => state.accounts.online_accounts,
    (state, accountId: string) => accountId
  ],
  (onlineAccounts: IOnlineAccount[], accountId: string) => onlineAccounts.find(onlineAccount => onlineAccount.id === accountId) !== undefined
);

export const {
  setOnlineAccounts,
  addOnlineAccount,
  removeOnlineAccount
} = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
