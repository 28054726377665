import * as Yup from 'yup';
import { isDate } from 'lodash';
import { useSnackbar } from 'notistack';
import { useForm, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState, useEffect, useCallback } from 'react';
import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Paper,
  Stack,
  Avatar,
  Dialog,
  Tooltip,
  Checkbox,
  Skeleton,
  useTheme,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import {
  WorkExperience,
  CandidateProfile,
  ExperienceVerificationType,
  CandidateProfileSectionType,
  ExperienceVerificationStatus,
} from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import RHFEditor from 'src/components/hook-form/rhf-editor';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import TruncatedHTMLRenderer from 'src/components/truncate-text/truncated-html-card';

import { TenantType } from 'src/types/enums';

import ExperienceVerificationModal from '../modals/request-verification-modal';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

type ExperienceFormValues = {
  company_name: string;
  position_title: string;
  role_summary: string;
  start: Date | null;
  end: Date | null;
  isCurrent: boolean;
};

export default function ProfileWorkHistory({ profile, isLoading, mode = 'edit' }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateProfile, { isLoading: isUpdating }] = useSetCandidateProfileMutation();

  const tenantType = useOrgTenant();

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState('auto');

  const showAddExperienceModal = useBoolean();

  const showVerificationModal = useBoolean();

  const [experiences, setExperiences] = useState<WorkExperience[]>([]);

  const [selectedExperience, setSelectedExperience] = useState<WorkExperience | undefined>(
    undefined
  );

  const [verificationExperienceId, setVerificationExperienceId] = useState<string>('');

  const onShowVerificationModal = (id: string) => {
    showVerificationModal.onTrue();
    setVerificationExperienceId(id);
  };

  const onHideVerificationModal = () => {
    showVerificationModal.onFalse();
    setVerificationExperienceId('');
  };

  const { t } = useTranslate();

  const theme = useTheme();

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(`${containerRef.current.offsetHeight + 50}px`);
    }
  }, [experiences]);

  const setProfileData = () => {
    if (!profile || isLoading) return;

    const sortedExperiences: WorkExperience[] =
      profile.work_experiences?.slice().sort((a: WorkExperience, b: WorkExperience) => {
        // First, sort by item_order
        if (a.item_order !== b.item_order) {
          return a.item_order - b.item_order;
        }

        // If item_order is the same, sort by start year
        if (a.start.year !== b.start.year) {
          return b.start.year - a.start.year;
        }

        // If start year is the same, sort by start month
        if (a.start.month !== b.start.month) {
          return b.start.month - a.start.month;
        }

        // If all above are equal, maintain the existing end date logic
        if (a.end?.month && b.end?.month) {
          const aDateMs = new Date(`${a.end.year}-${a.end.month}`).getTime();
          const bDateMs = new Date(`${b.end.year}-${b.end.month}`).getTime();
          return bDateMs - aDateMs;
        }

        if (!a.end?.month) {
          return -1;
        }

        return 1;
      }) || [];

    setExperiences(sortedExperiences);
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const onSaveExperiences = async (updatedExperiences: WorkExperience[]) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          sections: [
            {
              type: CandidateProfileSectionType.WORK_EXPERIENCES,
              visibility_rule: VisibilityRule.PUBLIC,
            },
          ],
          work_experiences: updatedExperiences.map((xp) => ({
            ...xp,
            end: xp.end?.year && xp.end?.month ? xp.end : undefined,
          })),
        },
      }).unwrap();

      enqueueSnackbar(t('profile.api.work_history.success'), { variant: 'success' });
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('profile.api.work_history.error'), { variant: 'error' });
    }
  };

  const onUpdateExperience = (experience: WorkExperience) => {
    const existingExperience = experiences.findIndex((exp) => exp.id === experience.id);
    let updatedExperiences = [];

    if (existingExperience !== -1) {
      updatedExperiences = [...experiences];
      updatedExperiences[existingExperience] = experience;
    } else {
      updatedExperiences = [experience, ...experiences];
    }

    onSaveExperiences(updatedExperiences);
  };

  const onRemoveExperience = (experienceId: string) => {
    const updatedExperiences = experiences.filter((exp) => exp.id !== experienceId);

    onSaveExperiences(updatedExperiences);
  };

  const onOpenEditor = (experienceId?: string) => {
    const selectedExp = experienceId
      ? experiences.find((exp) => exp.id === experienceId)
      : undefined;

    if (selectedExp) {
      setSelectedExperience(selectedExp);
    }

    showAddExperienceModal.onTrue();
  };

  const onCloseEditor = () => {
    setSelectedExperience(undefined);
    showAddExperienceModal.onFalse();
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(experiences);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // check if the order has actually changed
    const hasOrderChanged = items.some((item, index) => item.item_order !== index);

    if (hasOrderChanged) {
      // update item_order for each experience
      const updatedItems = items.map((item, index) => ({
        ...item,
        item_order: index,
      }));

      setExperiences(updatedItems);
      onSaveExperiences(updatedItems);
    }
  };

  const scrollToTop = useCallback((expanded: boolean) => {
    if (containerRef.current && !expanded) {
      const containerTop = containerRef.current.getBoundingClientRect().top + window.pageYOffset - 180;
      window.scrollTo({
        top: containerTop,
        behavior: 'smooth'
      });
    }
  }, [containerRef]);

  const renderForm = () => (
    <>
      {isLoading ? (
        <Grid xs={12}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="experiences">
            {(provided) => (
              <Stack direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                <Paper
                  variant="outlined"
                  sx={{
                    paddingX: 2,
                    paddingY: 3,
                    marginBottom: 2,
                    backgroundColor: theme.palette.background.neutral,
                    // height: containerHeight,
                    transition: 'height 0.2s ease-in-out',
                  }}
                >
                  <Stack spacing={2} display="flex" justifyContent="flex-start" ref={containerRef}>
                    {experiences?.length ? (
                      experiences.map((experience, index) => (
                        <Draggable
                          isDragDisabled={mode === 'view'}
                          key={experience.id}
                          draggableId={experience.id ?? ''}
                          index={index}
                        >
                          {(draggable, snapshot) => (
                            <Card
                              className="draggable-experience-card"
                              ref={draggable.innerRef}
                              {...draggable.draggableProps}
                              {...draggable.dragHandleProps}
                              id={`${experience.start.year}-${experience.end?.year ?? 'present'
                                }-${experience.company_name.split(' ').join('-')}`}
                              key={`${experience.start.year}-${experience.end?.year ?? 'present'
                                }-${experience.company_name.split(' ').join('-')}`}
                              sx={{
                                ...draggable.draggableProps.style,
                                backgroundColor: snapshot.isDragging ? '#eaeaea' : 'white',
                              }}
                            >
                              <CardHeader
                                sx={{
                                  flexWrap: 'wrap',
                                }}
                                title={experience.position_title}
                                subheader={experience.company_name}
                                avatar={
                                  <Avatar src="" alt={experience?.company_name}>
                                    {experience?.company_name?.charAt(0)}
                                  </Avatar>
                                }
                                action={
                                  mode === 'edit' && (
                                    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                      {experience.verification_status === ExperienceVerificationStatus.UNVERIFIED ? (
                                        <>
                                          {
                                            tenantType === TenantType.Candidate &&
                                            <Tooltip
                                              title={t('profile.sections.work_history.verify_tooltip')}
                                            >
                                              <Button
                                                variant="text"
                                                className="text-blue-500"
                                                endIcon={<Iconify icon="solar:verified-check-bold" />}
                                                onClick={() =>
                                                  onShowVerificationModal(experience.id as string)
                                                }
                                              >
                                                {t('profile.sections.work_history.verify_job')}
                                              </Button>
                                            </Tooltip>
                                          }
                                        </>

                                      ) : (
                                        <Tooltip
                                          title={
                                            experience.verification_type ===
                                              ExperienceVerificationType.INVITEE
                                              ? t(
                                                `profile.sections.work_history.${experience?.verification_status ===
                                                  ExperienceVerificationStatus.PENDING
                                                  ? 'verification_pending'
                                                  : 'verified'
                                                }`,
                                                {
                                                  domain: experience?.verified_by_domain,
                                                }
                                              )
                                              : t('profile.sections.work_history.verified_system')
                                          }
                                        >
                                          <Iconify
                                            icon="solar:verified-check-bold"
                                            mr={1}
                                            className={
                                              experience?.verification_status ===
                                                ExperienceVerificationStatus.PENDING
                                                ? 'text-gray-500'
                                                : 'text-blue-500'
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                      <IconButton
                                        className="text-gray-500"
                                        disabled={isUpdating}
                                        onClick={() => onOpenEditor(experience.id)}
                                      >
                                        <Iconify icon="solar:pen-bold" />
                                      </IconButton>
                                      <IconButton
                                        className="text-gray-500 delete-experience-button"
                                        disabled={isUpdating}
                                        onClick={() => onRemoveExperience(experience.id as string)}
                                      >
                                        <Iconify icon="mdi:delete" />
                                      </IconButton>
                                    </Stack>
                                  )
                                }
                              />
                              <CardContent>
                                <Grid container wrap="wrap">
                                  <Grid xs={12} md={6}>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                      {experience.duration_text}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} pt={1}>
                                    <TruncatedHTMLRenderer html={experience.role_summary} mode="lines" limit={3} onExpandChange={scrollToTop} />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Typography textAlign="center" fontWeight="bold" className="text-gray-400">
                        {t('profile.sections.work_history.no_experiences')}
                      </Typography>
                    )}
                  </Stack>
                </Paper>
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {mode === 'edit' && (
        <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
          <Button
            className="text-blue-500"
            onClick={() => onOpenEditor()}
            startIcon={<Iconify icon="solar:pen-bold" width={16} />}
            disabled={isUpdating || isLoading}
          >
            {t('profile.sections.work_history.add_experience')}
          </Button>
        </CardActions>
      )}
      <WorkExperienceEditModal
        open={showAddExperienceModal.value}
        onClose={onCloseEditor}
        experience={selectedExperience}
        addExperience={onUpdateExperience}
      />
      <ExperienceVerificationModal
        open={showVerificationModal.value}
        onClose={onHideVerificationModal}
        experienceId={verificationExperienceId}
      />
    </>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.work_history.title')}
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}

type ModalProps = {
  open: boolean;
  onClose: VoidFunction;
  experience?: WorkExperience;
  addExperience: (experience: WorkExperience) => void;
};

function WorkExperienceEditModal({ open, onClose, experience, addExperience }: ModalProps) {
  const { t } = useTranslate();

  const theme = useTheme();

  const defaultValues: ExperienceFormValues = {
    company_name: '',
    position_title: '',
    role_summary: '',
    start: null,
    end: null,
    isCurrent: false,
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required(t('validation.required')),
    position_title: Yup.string().required(t('validation.required')),
    role_summary: Yup.string()
      .max(2000, t('validation.max_length', { length: 2000 }))
      .required(t('validation.required')),
    start: Yup.date().required(t('validation.required')),
    end: Yup.date()
      .when(['start', 'isCurrent'], ([start, isCurrent], schema) =>
        !isCurrent && isDate(start) ? schema.min(start, t('validation.min_end_date')) : schema
      )
      .when('isCurrent', ([isCurrent], schema) =>
        !isCurrent ? schema.required(t('validation.required')) : schema.nullable()
      ),
    // .test(
    //   "dates-test",
    //   "End date should be after start date",
    //   (value, context) => {
    //     let startDate = moment(context.parent.sa, "MM/DD/YYYY").toDate();
    //     let endDate = moment(value, "MM/DD/YYYY").toDate();
    //     return endDate > startDate;
    //   }
    // ),
    isCurrent: Yup.boolean(),
  });

  const formMethods = useForm<ExperienceFormValues>({
    resolver: yupResolver(validationSchema) as Resolver<ExperienceFormValues>,
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset,
    setValue,
    register,
  } = formMethods;

  const setFormValues = useCallback(() => {
    if (!experience) return;

    setValue('company_name', experience.company_name);
    setValue('position_title', experience.position_title);
    setValue('role_summary', experience.role_summary);
    setValue('start', new Date(`${experience.start.year}-${experience.start.month}`));

    if (experience.end?.month && experience.end?.year) {
      setValue('end', new Date(`${experience.end?.year}-${experience.end?.month}`));
      setValue('isCurrent', false);
    } else {
      setValue('end', null);
      setValue('isCurrent', true);
    }
  }, [experience, setValue]);

  useEffect(() => {
    if (!experience) return;

    setFormValues();
  }, [experience, setFormValues]);

  const onSubmit = handleSubmit((data) => {
    addExperience({
      ...(experience || {}),
      company_name: data.company_name,
      position_title: data.position_title,
      role_summary: data.role_summary,
      start: {
        month: (data.start as Date).getMonth() + 1,
        year: (data.start as Date).getFullYear(),
      },
      end: data.end
        ? {
          month: data.end.getMonth() + 1,
          year: data.end.getFullYear(),
        }
        : undefined,
      verified_at: '',
      item_order: 0,
      verified_by_domain: null,
    });

    onCloseDialog();
  });

  const startDate = watch('start');
  const endDate = watch('end');
  const isCurrent = watch('isCurrent');

  const onCloseDialog = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>
        {t(`profile.sections.work_history.modal.title_${experience ? 'edit' : 'add'}`)}
      </DialogTitle>
      <DialogContent sx={{ pt: 4, pb: 2 }}>
        <FormProvider methods={formMethods} onSubmit={onSubmit}>
          <Grid container spacing={2} sx={{ py: 2 }}>
            <Grid xs={6}>
              <RHFTextField
                name="company_name"
                label="Company Name"
                fullWidth
                sx={{ backgroundColor: theme.palette.background.paper }}
                ref={null}
              />
            </Grid>
            <Grid xs={6}>
              <RHFTextField
                name="position_title"
                label="Position Title"
                fullWidth
                sx={{ backgroundColor: theme.palette.background.paper }}
                ref={null}
              />
            </Grid>
            <Grid xs={6} xsOffset={6} py={0}>
              <FormControlLabel
                label="Current Role"
                control={<Checkbox {...register('isCurrent')} />}
                sx={{ ml: 0.125, pb: 0 }}
              />
            </Grid>
            <Grid xs={6}>
              <Stack direction="row" spacing={1}>
                <MobileDatePicker
                  name="start"
                  views={['year', 'month']}
                  label="Start Date"
                  minDate={new Date('1990-01-01')}
                  maxDate={new Date()}
                  value={startDate ?? null}
                  onChange={(value: Date | null) => {
                    setValue('start', value, { shouldDirty: true, shouldValidate: true });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                    },
                  }}
                  sx={{ mt: 0 }}
                />
              </Stack>
            </Grid>
            <Grid xs={6}>
              <Stack direction="column" spacing={1}>
                <MobileDatePicker
                  name="end"
                  views={['year', 'month']}
                  label="End Date"
                  minDate={new Date('1970-01-01')}
                  maxDate={new Date()}
                  value={endDate ?? null}
                  onChange={(value: Date | null) => {
                    setValue('end', value, { shouldDirty: true, shouldValidate: true });
                  }}
                  disabled={isCurrent}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                      style: {
                        borderColor: !!errors.end ? theme.palette.error.main : 'inherit',
                      },
                    },
                  }}
                  sx={{ mt: 0, mb: 0 }}
                />
                <FormHelperText sx={{ mt: 0, pl: 1 }} error={!!errors.end}>
                  {errors.end?.message}
                </FormHelperText>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <RHFEditor
                name="role_summary"
                sx={{ backgroundColor: theme.palette.background.paper }}
              />
            </Grid>
            <Grid xs={12}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="text" onClick={onCloseDialog}>
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="contained"
                  disabled={!isDirty}
                  endIcon={
                    <Iconify icon={experience ? 'solar:pen-bold' : 'material-symbols:add'} />
                  }
                  type="submit"
                >
                  {experience ? t('common.save') : t('common.add')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
