//

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { debounce, isString } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState, useEffect, SyntheticEvent } from 'react';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Stack, Button, Checkbox, useTheme, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useTranslate } from 'src/locales';
import { Category } from 'src/services/utils/utils.types';
import { useLazySearchCategoriesQuery } from 'src/services/utils/utils.service';
import { ConnectionSettings } from 'src/services/connections/connections.types';

import RHFSwitch from 'src/components/hook-form/rhf-switch';
import FormProvider from 'src/components/hook-form/form-provider';
import QuantityInput from 'src/components/number-input/quantity-input';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type Props = {
  onNextPage: (data: ConnectionSettings) => void;
  onBack: VoidFunction;
  submitting: boolean;
};

export default function ConnectionSettingsForm({ onNextPage, onBack, submitting = false }: Props) {
  const { t } = useTranslate();

  const theme = useTheme();

  const [jobsLimit, setJobsLimit] = useState<number>(1);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchCategories, { currentData: categoriesData }] = useLazySearchCategoriesQuery();

  const ConnectionSearchSchema = Yup.object().shape({
    job_limit_applied: Yup.boolean().default(false),

    jobs_limit: Yup.number().integer(),

    specializations: Yup.array().of(Yup.object()),
  });

  const defaultValues = {
    job_limit_applied: false,
    jobs_limit: 0,
    specializations: [],
  };

  const methods = useForm({
    resolver: yupResolver(ConnectionSearchSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = methods;

  const jobLimitApplied = watch('job_limit_applied');

  const submitForm = handleSubmit((data) => {
    onNextPage({
      job_limit_applied: data.job_limit_applied,
      jobs_limit: data.jobs_limit as number,
      specializations: (data.specializations as Category[]).map((item) => item.id),
    });
  });

  const onChangeJobLimit = (event: FocusEvent | PointerEvent | KeyboardEvent, value: number) => {
    event.preventDefault();

    setJobsLimit(value);
    setValue('jobs_limit', value);
  };

  const debounceSearchCategories = useMemo(
    () =>
      debounce((input: string) => {
        searchCategories({
          name: input,
          page: 1,
          per_page: 5,
        });
      }),
    [searchCategories]
  );

  useEffect(() => {
    if (!searchQuery) return;

    debounceSearchCategories(searchQuery);
  }, [debounceSearchCategories, searchQuery]);

  const onSearch = (_event: SyntheticEvent, value: string) => setSearchQuery(value);

  const onSelectSpecialization = (_event: SyntheticEvent, value: string[]) => {
    if (!value) return;

    setValue('specializations', [...value]);
  };

  return (
    <Stack>
      <FormProvider methods={methods} onSubmit={submitForm}>
        <Grid
          container
          sx={{ backgroundColor: theme.palette.grey[100], borderRadius: 2, width: '100%' }}
          mt={1}
          columns={12}
          gap={4}
          padding={2}
        >
          <Grid xs={12}>
            <Typography variant="h6">
              {t('admin-connections.action_modal.connection_settings')}
            </Typography>
          </Grid>
          <Grid xs={12} sx={{ typography: 'subtitle2' }}>
            {t('admin-connections.jobs_limit_form.specialisations')}
            <RHFAutocomplete
              multiple
              fullWidth
              onInputChange={onSearch}
              disableCloseOnSelect
              name="specializations"
              placeholder="Start typing to search specialisations"
              options={categoriesData?.results ?? []}
              onChange={onSelectSpecialization as any}
              filterOptions={(x) => x}
              renderOption={(props, option: Category, { selected }) => (
                <li {...props}>
                  <Stack direction="row">
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Typography variant="body1">{option.name}</Typography>
                  </Stack>
                </li>
              )}
              getOptionLabel={(option: Category | string) =>
                isString(option) ? option : option.name
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionKey={(option) => (option as Category).id as string}
            />
          </Grid>

          <Grid xs={12} sx={{ typography: 'subtitle2' }}>
            <Stack direction="row" alignItems="center" justifyContent="start" gap={3}>
              <Box>
                <RHFSwitch label={t('admin-connections.jobs_limit_form.limit_label')} name="job_limit_applied" />
              </Box>

              {
                jobLimitApplied &&
                <Box>
                  <QuantityInput min={1} max={99} value={jobsLimit} onChange={onChangeJobLimit as any} />
                </Box>
              }
            </Stack>
          </Grid>
        </Grid>

        <Stack sx={{ pt: 3, width: '100%' }} flexDirection="row" justifyContent="flex-end">
          <Button variant="outlined" onClick={onBack}>
            {t('common.back')}
          </Button>
          <LoadingButton
            loading={submitting}
            type="submit"
            variant="contained"
            disabled={!isValid || submitting}
            sx={{ ml: 1 }}
          >
            {t('common.submit')}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Stack>
  );
}
