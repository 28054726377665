
import { useMemo, useCallback } from 'react';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { Card, Chip, Grid, Button, Skeleton, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useFeatures } from 'src/usage/hooks/use-features';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { IntegrationStatus, IntegrationProvider, IntegrationProviderType } from 'src/services/integration/integrations.types';
import { useInitiateConnectMutation, useGetAvailableIntegrationsQuery, useGetConnectedIntegrationsQuery } from 'src/services/integration/integrations.service';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { useTable } from 'src/components/table';
import { useSettingsContext } from 'src/components/settings';
import FeatureLockedChip from 'src/components/billing/feature-locked-chip';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

import { FeatureType } from 'src/types/subscription.types';

import IntegrationsToolbar from '../integrations-toolbar';

// ----------------------------------------------------------------------

const DEFAULT_PAGE_OPTIONS: PageOptions = {
  page: 1,
  per_page: 10,
};

// ----------------------------------------------------------------------

export default function IntegrationsListView() {
  const { t } = useTranslate();

  const authContext = useAuthContext();

  const table = useTable({ defaultRowsPerPage: 10 });

  const settings = useSettingsContext();

  const confirm = useBoolean();

  const router = useRouter();

  const { canAccess } = useFeatures();

  const { currentData: availableIntegrations, isLoading: isRetrievingAvailableIntegrations } = useGetAvailableIntegrationsQuery();
  const { currentData: connectedIntegrations, isLoading: isRetrievingConnectedIntegrations } = useGetConnectedIntegrationsQuery({
    page: 1,
    per_page: 100
  });

  const [initiateConnect, { isLoading: isRetrievingConnectionUrl }] = useInitiateConnectMutation();

  const handleFilters = (type: string, value: string | boolean) => {

  };

  const clearFilters = () => {

  };

  const isConnected = useCallback((providerType: IntegrationProviderType) => {
    if (!connectedIntegrations) return false;
    return connectedIntegrations.results.find((integration) => integration.provider_type === providerType) !== undefined;
  }, [connectedIntegrations]);

  const renderStatusLabel = useCallback((providerType: IntegrationProviderType) => {
    const connectedIntegration = connectedIntegrations?.results.find((integration) => integration.provider_type === providerType);
    let status;

    if (connectedIntegration) {
      status = connectedIntegration.status;
    }

    switch (status) {
      case IntegrationStatus.ACTIVE:
        return (
          <Chip label={t('common.connected')} color="success" variant='outlined' size='small' />
        );
      case IntegrationStatus.REQUIRES_SETUP:
        return (
          <Chip label={t('common.setup_required')} color="default" variant='outlined' size='small' />
        );
      case IntegrationStatus.EXPIRED:
        return (
          <Chip label={t('common.expired')} color="error" variant='outlined' size='small' />
        );
      default:
        return (
          <Chip label={t('common.disconnected')} color="default" variant='outlined' size='small' />
        );
    }
  }, [connectedIntegrations, t]);

  // sorted by connected first
  const sortedIntegrations = useMemo(() => {
    if (!availableIntegrations) return [];
    return availableIntegrations.sort((a, b) => {
      if (isConnected(a.type) && !isConnected(b.type)) {
        return -1;
      }
      if (!isConnected(a.type) && isConnected(b.type)) {
        return 1;
      }
      return 0;
    });
  }, [availableIntegrations, isConnected]);

  const handleInitiateConnect = async (provider: IntegrationProvider) => {

    const connectionUrl = await initiateConnect({
      provider: provider.type
    }).unwrap();
    if (connectionUrl) {
      // navigate to connection url
      window.location.replace(connectionUrl);
    }
  }

  const viewIntegration = (provider: IntegrationProvider) => {
    if (!connectedIntegrations) return;

    const connectedIntegration = connectedIntegrations.results.find((integration) => integration.provider_type === provider.type);
    if (!connectedIntegration) return;

    // navigate
    router.push(paths.dashboard.admin.integrations.detail.configuration(connectedIntegration.id));

  }

  const renderProvider = (provider: IntegrationProvider) => (
    <Card sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
        {renderStatusLabel(provider.type)}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Image src={provider.logo} alt={provider.name} sx={{ height: 24, mr: 2, my: 2, objectFit: 'cover' }} />

      </Box>
      <Typography variant="h6" component="div">
        {provider.name}
      </Typography>
      <TruncatedTextField text={provider.description} variant="body2" color="text.secondary" sx={{ flexGrow: 1, mb: 2 }} mode='lines' limit={3} showMoreEnabled={false} />
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        {
          provider.tags.map((tag: string) => (
            <Chip label={tag} variant='outlined' size="small" key={tag} />
          ))
        }
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <>
          {
            canAccess(FeatureType.Integrations) ?
              <>
                {
                  isConnected(provider.type) ? (
                    <Button variant="contained" startIcon={<Iconify icon="" />} onClick={() => viewIntegration(provider)} disabled={!provider.enabled}>
                      {t('common.manage')}
                    </Button>
                  ) : (
                    <LoadingButton variant="contained" loading={isRetrievingConnectionUrl} color="primary" disabled={!provider.enabled} startIcon={<Iconify icon="" />} onClick={() => handleInitiateConnect(provider)}>
                      {t('common.connect')}
                    </LoadingButton>
                  )
                }
              </>
              :
              (
                <FeatureLockedChip text={t('common.upgrade')} />
              )
          }
        </>

        <Box>
          {
            provider.privacy_policy && (
              <Button variant="text" size="small" href={provider.privacy_policy} target="_blank">
                {t('common.privacy')}
              </Button>
            )
          }

          {
            provider.terms_of_service && (
              <Button variant="text" size="small" href={provider.terms_of_service} target="_blank">
                {t('common.terms')}
              </Button>
            )
          }
        </Box>
      </Stack>
    </Card>
  );

  return (
    <Stack direction="column" gap={2}>
      <Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <IntegrationsToolbar
            onFilters={handleFilters}
            clearFilters={clearFilters}
          />
        </Stack>
      </Card>

      <Grid container spacing={3}>
        {
          (isRetrievingAvailableIntegrations || isRetrievingConnectedIntegrations) ? (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant="rounded" sx={{ height: 200 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant="rounded" sx={{ height: 200 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant="rounded" sx={{ height: 200 }} />
              </Grid>
            </>
          )
            :
            (
              sortedIntegrations.map((provider) => (
                <Grid item xs={12} sm={6} md={4} key={provider.type}>
                  {renderProvider(provider)}
                </Grid>
              ))
            )
        }

      </Grid>
    </Stack>
  );
}
