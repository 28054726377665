import type { Category } from 'src/services/utils/utils.types';

import { TenantType } from 'src/types/enums';

import type { Organization } from '../auth/auth.types';
import type { IJob, JobFilters } from '../jobs/jobs.types';
import type { AccountStatus } from '../account/account.types';
import { PageOptions, PaginatedResponse } from '../api.types';
import type { CompanySizeRange } from '../organisation/organisation.types';


export type GetConnectionsRequest = {
  organization_id: string;
  search_query?: string;
  status?: ConnectionStatus;
} & PageOptions;

export interface GetConnectionDetailsProps {
  organization_id: string;
  request_id: string;
}

export interface Connection {
  id: string;
  status: ConnectionStatus;
  connected_organization: ConnectionOrg;
  thread: { id: string };
  approved_specializations: Category[];
  job_limit: null | number;
  created_at: string;
}

export interface ConnectionOrg {
  id: string;
  type: TenantType;
  name: string;
  company_information: string;
}

export enum RequestStatus {
  UNAPPROVED = 'unapproved',
  DENIED = 'denied',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  CLOSED = 'closed',
}

export enum ConnectionStatus {
  PENDING = 'pending',
  UNAPPROVED = 'unapproved',
  CONNECTED = 'connected',
  CLOSED = 'closed',
}

export interface ConnectionRequestActionPayload {
  connectionId: string;
  action: ConnectionRequestAction;
}

export interface ConnectionRequestAction {
  action: ConnectionActionOption;
  connection_settings?: ConnectionSettings;
}

export enum ConnectionActionOption {
  // approve, deny from sender, accept, reject from receiver
  Accept = 'accept',
  Reject = 'reject',
  Approve = 'approve',
  Deny = 'deny',
}

export interface ConnectionSettings {
  job_limit_applied: boolean;
  jobs_limit: number;
  specializations: string[];
}

export interface ConnectionRequest {
  id: string;
  status: RequestStatus;
  client: CompanyProfile;
  recruiter: CompanyProfile;

  initiator_type: TenantType;
  initiator_account: {
    id: string;
    first_name: string;
    last_name: string;
  };

  created_at: string;
  settings_data: ConnectionSettings;

  invites: { email: string; name: string }[];

  terms_type: ConnectionTermsType;
  documents: { id: string; name: string; file: { public_path: string } }[];
  requested_terms: { id: string, content: string }[]
}

export interface CompanyProfile {
  id: string;
  status?: AccountStatus;
  company_name: string;
  company_type: TenantType;
  company_information: string;
  organization?: Pick<Organization, 'id' | 'status' | 'type'>;
  type: CompanyProfileType;
  company_size?: CompanySizeRange;
  company_logo?: {
    id: string;
    public_path: string;
  } | null;
  country_code?: string;
  tax_id?: string;
  availability_shared?: boolean;
}

export interface IniateConnectonRequestPops {
  organization_id: string;
  request: ConectionRequestData;
}

export interface UpdateConnectonSettingsPops {
  organization_id: string;
  connection_id: string;
  settings: ConnectionSettings;
}

export interface ConectionRequestData {
  target: ConnectionTargetData;

  terms_settings: ConnectionTermsSettings;

  // only applicable if tenant type is client
  connection_settings: ConnectionSettings;
}

export interface ConnectionTargetData {
  target_id?: string;
  company_name?: string;
  email?: string;
  name?: string;
  additional_invites?: { email: string; }[];
}

export interface ConnectionTermsSettings {
  // Option 1
  terms_type?: ConnectionTermsType;
  terms_id?: string;

  // Option 2
  documents?: [
    {
      id: string;
    },
  ];

  // Option 3
  // TOB //template
  template_data?: {
    date: string;
    rate: string;
    payment_terms: string;
    special_conditions?: string;
  };
}

export enum TermsType {
  TERMS_OF_USE = 'terms_of_use',
  TERMS_OF_ENGAGEMENT = 'terms_of_engagement',
}

export interface UnlinkConnectionProps {
  organization_id: string;
  connection_id: string;
}

export type GetConnectionRequestProps = {
  organization_id: string;
  target: ConnectionTarget;
} & PageOptions;

export enum ConnectionTarget {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export enum ConnectionTermsType {
  Terms_Of_Engagement = 'terms_of_engagement',
  Template = 'tob_template',
  Custom = 'tob_upload',
}

export type ConnectionSearchProps = {
  params: { include_system_profiles?: boolean, search_query?: string } & PageOptions & JobFilters;
  target: InteractedTargets;
};

export enum InteractedTargets {
  clients = 'clients',
  recruiters = 'recruiters',
}

export enum CompanyProfileType {
  UNLINKED = 'unlinked',
  PRIMARY = 'primary',
  SYSTEM = 'system',
}

export type GetConnectionResourceProps = {
  params: PageOptions;
  profile_id: string;
  target: InteractedTargets;
};

export type GetProfileById = {
  profile_id: string;
  target: InteractedTargets;
};

export type ProfileJobsResponse = PaginatedResponse<ProfileJob>;

export type ProfileJob = Pick<
  IJob,
  'description' | 'id' | 'industry' | 'status' | 'title' | 'verification_status' | 'vacancies'
>;
