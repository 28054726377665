import Stack from '@mui/system/Stack';

import { CandidateProfile } from 'src/services/candidates/candidates.types';

import ProfileBio from './profile-bio';
import ProfileSkillsEditor from './profile-skills';
import ProfileWorkHistory from './profile-work-history';
import ProfileSalaryEditor from './profile-salary-editor';
import ProfileCurrentInformation from './profile-basic-info';
import WorkPreferencesEditor from './work-preferences-editor';
import ProfileEducationHistory from './profile-education-history';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function CandidateProfileEditor({ profile, isLoading, mode = 'edit' }: Props) {
  return (
    <Stack spacing={3}>
      <ProfileBio profile={profile} isLoading={isLoading} mode={mode} />

      <ProfileSkillsEditor profile={profile} isLoading={isLoading} mode={mode} />

      <ProfileWorkHistory profile={profile} isLoading={isLoading} mode={mode} />

      <ProfileEducationHistory profile={profile} isLoading={isLoading} mode={mode} />

      <ProfileCurrentInformation profile={profile} isLoading={isLoading} mode={mode} />

      <WorkPreferencesEditor profile={profile} isLoading={isLoading} mode={mode} />

      <ProfileSalaryEditor profile={profile} isLoading={isLoading} mode={mode} />
    </Stack>
  );
}
