import { Outlet } from 'react-router';
import { Helmet } from 'react-helmet-async';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

export default function GlobalAccountsPage() {
  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title> Admin: Accounts</title>
      </Helmet>

      <Container
        maxWidth="lg"
        sx={{
          height: 1,
        }}
      >
        <Stack spacing={2}>
          <CustomBreadcrumbs
            heading="Admin: Organisations"
            links={[
              { name: t('common.dashboard'), href: paths.dashboard.root },
              {
                name: 'Accounts',
                href: undefined,
              },
            ]}
          />

          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
