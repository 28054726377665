import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/system';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { authApi } from 'src/services/auth/auth.service';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { AuthContext } from 'src/services/auth/auth.types';
import { SystemRole } from 'src/services/organisation/organisation.enum';
import { KnowledgeBaseButton } from 'src/layouts/common/support/knowledge-base-button';

import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';

import { NAV, HEADER } from '../config-layout';
import AccountPopover from '../common/account-popover';
import NotificationsPopover from '../common/notifications-popover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { currentData: authData } = authResult;
  const { user } = authData as AuthContext;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tenant = useOrgTenant();

  const settings = useSettingsContext();

  const router = useRouter();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const smUp = useResponsive('up', 'sm');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const viewSystemDashboard = () => {
    router.push(`${paths.dashboard.restricted.settings.root}`);
  };

  const isSuperAdmin = user.system_role === SystemRole.SUPER_ADMIN;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
        <Box>
          {
            (isSuperAdmin && !isMobile) && <Button variant='contained' startIcon={<Iconify icon="solar:lock-keyhole-minimalistic-unlocked-bold-duotone" />} onClick={viewSystemDashboard}>
              System Dashboard
            </Button>
          }
        </Box>

        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1 }}
        >
          <KnowledgeBaseButton />

          {/* <LanguagePopover /> */}

          {/* {smUp && !isMobile && tenant === TenantType.Candidate && <ChromeExtensionLink />} */}

          <NotificationsPopover />

          {/* <SettingsButton /> */}

          <AccountPopover />
        </Stack>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.background.neutral,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
