


import { Chip, Tooltip, ChipProps } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  text?: string;
} & ChipProps;

export default function FeatureLockedChip({
  text,
  ...chipProps
}: Props) {

  const { t } = useTranslate();
  const router = useRouter();

  const handleClick = () => {
    router.push(paths.dashboard.admin.billing.root)
  }

  return (
    <Tooltip title={t('billing.feature_locked_tooltip')} onClick={handleClick} sx={{ cursor: 'pointer' }} >
      <Chip
        label={text ?? t('candidates.table.availability_status_no_access')}
        size='small'
        color='default'
        icon={<Iconify icon="ep:lock" width={15} height={15} />}
        {...chipProps}
      />
    </Tooltip>
  );
}
