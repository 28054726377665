import { useMemo, useState, useEffect } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';

import { useTheme } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { isAdmin } from 'src/services/auth/auth.utils';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setShowJoyride, setShowNavMenu } from 'src/store/slices/joyride/joyrideSlice';
import { useUpdateOnboardingContextMutation } from 'src/services/account/account.service';

import { TenantType } from 'src/types/enums';

import AdminSteps from './admin-steps';
import ClientSteps from './client-steps';
import CandidateSteps from './candidate-steps';
import RecruiterSteps from './recruiter-steps';

export default function JoyrideContainer() {
  const router = useRouter();

  const tenant = useOrgTenant();

  const authContext = useAuthContext();

  const [runKey, setRunKey] = useState(0);

  const [overlayHeight, setOverlayHeight] = useState(document.body.scrollHeight);

  const { t } = useTranslate();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const showWelcomeJoyride = useAppSelector((state) => state.joyride.showWelcomeJoyride);

  const [updateOnboardingStatus] = useUpdateOnboardingContextMutation();

  const [steps, setSteps] = useState<any[]>();

  const isAdminUser = useMemo(() => isAdmin(authContext), [authContext]);

  useEffect(() => {
    switch (tenant) {
      case TenantType.Candidate:
        setSteps(CandidateSteps);
        break;
      case TenantType.Recruiter:
        setSteps(isAdminUser ? [...RecruiterSteps, ...AdminSteps] : RecruiterSteps);
        break;
      case TenantType.Client:
        setSteps(isAdminUser ? [...ClientSteps, ...AdminSteps] : ClientSteps);
        break;
      default:
        setSteps(CandidateSteps);
        break;
    }
  }, [isAdminUser, tenant]);

  const handleCallback = (data: CallBackProps) => {
    const {
      action,
      step: {
        data: { path, nextPath, previousPath, navMenu },
      },
    } = data;
    const isPreviousAction = action === 'prev';

    if (!isPreviousAction) {
      if (path !== nextPath) {
        router.push(nextPath);
      }
    } else if (path !== previousPath) {
      router.push(previousPath);
    }

    if (navMenu) {
      dispatch(setShowNavMenu(true));
    } else {
      dispatch(setShowNavMenu(false));
    }

    setOverlayHeight(document.body.scrollHeight);

    if (action === 'close' || action === 'skip' || action === 'reset') {
      updateOnboardingStatus({
        signup_flow: true,
      });
      dispatch(setShowJoyride(false));
      setRunKey(runKey + 1);
    }
  };

  return (
    <Joyride
      key={runKey}
      run={showWelcomeJoyride}
      steps={steps}
      continuous
      showProgress
      showSkipButton
      disableScrolling
      callback={handleCallback}
      styles={{
        options: {
          zIndex: 2000,
        },
        overlay: {
          height: overlayHeight,
        },
        // buttonClose: buttonClose,
        buttonNext: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        buttonBack: {
          color: theme.palette.text.primary,
        },
        // buttonSkip: buttonSkip,
        // tooltip: tooltip,
      }}
    />
  );
}
