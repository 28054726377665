/* eslint-disable perfectionist/sort-imports */
import 'src/utils/highlight';

import sanitizeHtml from 'sanitize-html';

import ReactQuill from 'react-quill';

import { alpha } from '@mui/material/styles';

import { useRef, useEffect } from 'react';
import { EditorProps } from './types';
import { StyledEditor } from './styles';
import Toolbar, { formats } from './toolbar';

// ----------------------------------------------------------------------

export default function Editor({
  id = 'minimal-quill',
  error,
  simple = false,
  noHeader = false,
  helperText,
  disabled,
  sx,
  ...other
}: EditorProps) {
  const quillRef = useRef<ReactQuill>(null);

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  const sanitizeClipboard = (clipboard: ClipboardEvent) => {
    clipboard.preventDefault();

    const { clipboardData } = clipboard;

    if (!clipboardData || clipboardData.types.indexOf('text/html') < 0) return;

    const html = clipboardData.getData('text/html');

    const sanitizedHtml = sanitizeHtml(html);

    quillRef.current?.editor?.clipboard.dangerouslyPasteHTML(sanitizedHtml);
  };

  useEffect(() => {
    if (!quillRef.current) return;

    const quill = quillRef.current.getEditor();

    quill.root.addEventListener('paste', sanitizeClipboard);

    // eslint-disable-next-line consistent-return
    return () => {
      quill.root.removeEventListener('paste', sanitizeClipboard);
    };
  }, []);

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Toolbar id={id} simple noHeader={noHeader} />

        <ReactQuill
          ref={quillRef}
          modules={modules}
          formats={formats}
          readOnly={disabled}
          theme="snow"
          placeholder="Write something awesome..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
