import moment from 'moment';
import React, { useMemo } from 'react';

import { Stack } from '@mui/system';
import {
  Card,
  Chip,
  Grid,
  Divider,
  Skeleton,
  useTheme,
  Typography,
  CardContent,
  useMediaQuery,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { ProductType } from 'src/services/billing/billing.types';
import { useGetOrganizationQuery } from 'src/services/admin/admin.service';
import { useGetProductsQuery, useGetOrganizationSubscriptionQuery } from 'src/services/billing/billing.services';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

type Props = {
  organizationId: string;
};

export default function SubscriptionCard({ organizationId }: Props) {
  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: organization, isLoading: isOrgLoading } = useGetOrganizationQuery(
    {
      id: organizationId as string,
    },
    { skip: !organizationId }
  );

  const { data: subscription, isError, isLoading: isSubLoading } = useGetOrganizationSubscriptionQuery(
    {
      organization_id: organizationId,
    },
    {
      skip: !organizationId,
    }
  );

  const { currentData: availableProducts, isLoading: isProductsLoading } = useGetProductsQuery();

  const activeProduct = useMemo(() => {
    if (!availableProducts || !subscription) return null;

    return availableProducts?.find(
      (product) => {
        const basePlan = subscription?.products.find((subProduct) => subProduct.identifier === product.identifier && product.type === ProductType.Base);
        if (!basePlan) return false;
        return product.identifier === basePlan.identifier
      }
    );
  }, [availableProducts, subscription]);

  if (isSubLoading || isOrgLoading || isProductsLoading) {
    return (
      <Card>
        <CardContent>
          <Skeleton variant="rectangular" width="100%" height={118} />
        </CardContent>
      </Card>
    );
  }

  if (isError || !subscription) {
    return (
      <Card>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            {t('restricted.organizations.detail.billing.subscription.unsubscribed')}
          </Typography>

        </CardContent>
      </Card>
    );
  }

  const renderCurrentPlan = () => (
    <Stack spacing={2} sx={{ p: 3, pt: 0, typography: 'body2' }}>
      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack spacing={1} direction="row" justifyContent="start" alignItems="center" >
        {
          subscription?.marked_for_cancellation && (
            <Chip label={t('billing.subscription.marked_for_cancellation')} color="warning" size='small' />
          )
        }

      </Stack>
      <Grid container spacing={{ xs: 0.5, md: 2 }}>
        <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
          {t('billing.subscription.current_plan')}
        </Grid>
        <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
          {activeProduct?.name || '-'}
        </Grid>
      </Grid>

      {!!activeProduct?.subscription_required && (
        <Grid container spacing={{ xs: 0.5, md: 2 }}>
          <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
            {t(
              `billing.subscription.${subscription?.marked_for_cancellation ? 'ends' : 'renews'
              }`
            )}
          </Grid>
          <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
            {moment(subscription?.ends_at).format('LL') || '-'}
          </Grid>
        </Grid>
      )}

      {
        activeProduct?.quantity_required && (
          <>
            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                {t('billing.subscription.seats')}
              </Grid>
              <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                {subscription?.products[0]?.quantity ?? 1}
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 0.5, md: 2 }}>
              <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                {t(`billing.product.max_seats_${organization?.type}`)}
              </Grid>
              <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                {activeProduct ? activeProduct?.max_quantity : '1'}
              </Grid>
            </Grid>
          </>
        )
      }


      {
        activeProduct?.subscription_required && (
          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              {t('billing.subscription.discount_applied')}
            </Grid>
            <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
              {subscription?.discount_value
                ? `${subscription?.discount_value} ${subscription?.discount_type === 'percentage' ? '%' : activeProduct?.currency
                }`
                : '--'}
            </Grid>
          </Grid>
        )
      }

    </Stack>
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h6">
                  {t('restricted.organizations.detail.billing.subscription.title')}
                </Typography>
                <Label
                  color="info"
                  startIcon={<Iconify icon="eva:star-fill" />}
                >
                  {t(`billing.subscription.status.${subscription?.status ?? 'active'}`)}
                </Label>
              </Stack>

              {
                subscription.marked_for_cancellation && (
                  <Chip
                    label={t('billing.subscription.marked_for_cancellation')}
                    color="warning"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )
              }
            </Stack>
          </Grid>

          <Grid item xs={12}>
            {renderCurrentPlan()}
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}