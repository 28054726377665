import { isString } from 'lodash';

import { ClientApplicationStages } from 'src/utils/applications-kanban';

import i18n from 'src/locales/i18n';

import { TenantType } from 'src/types/enums';

import { applicationsApi } from './applications.service';
import { Application, ApplicationStage } from './applications.types';

export const optimisticallyProgressCandidateApplicationStatus = (
  jobId: string,
  applicationId: string,
  stage: string
) =>
  applicationsApi.util.updateQueryData(
    'getJobApplications',
    {
      jobId,
      params: {
        page: 1,
        per_page: 999,
      },
    },
    (applicationsResponse) => ({
      ...applicationsResponse,
      results: applicationsResponse.results.map((application: Application) =>
        application.id === applicationId
          ? { ...application, stage: stage as ApplicationStage }
          : application
      ),
    }),
    true
  );

export const isApplicationFinalized = (stage: ApplicationStage) => [
  ApplicationStage.REJECTED,
  ApplicationStage.WITHDRAWN,
  ApplicationStage.TO_BE_REJECTED,
  ApplicationStage.OFFER_ACCEPTED
].includes(stage)

export const getConfirmStageChangeContentMessage = (targetStage: ApplicationStage, tenantType: TenantType) => {
  const targetStageLabel = i18n.t(`enums.application_status.${targetStage}`);

  if (targetStage === ApplicationStage.REJECTED) {
    return i18n.t('applications.stage_change.reject_application');
  }

  if (targetStage === ApplicationStage.SHORTLISTED && tenantType === TenantType.Recruiter) {
    return i18n.t('applications.stage_change.confirm_shortlist_recruiter');
  }

  if (targetStage === ApplicationStage.SHORTLISTED && tenantType === TenantType.Client) {
    return i18n.t('applications.stage_change.confirm_shortlist_client');
  }

  if (ClientApplicationStages.includes(targetStage) && tenantType === TenantType.Recruiter) {
    return i18n.t('applications.stage_change.confirm_alert_client', {
      target: targetStageLabel,
    });
  }

  if (ClientApplicationStages.includes(targetStage) && tenantType === TenantType.Client) {
    return i18n.t('applications.stage_change.confirm', {
      target: targetStageLabel,
    });
  }

  return i18n.t('applications.stage_change.confirm', {
    target: targetStageLabel,
  });
};

export const getConfirmStageChangeRequestContentMessage = (targetStage: ApplicationStage) => {
  const targetStageLabel = i18n.t(`enums.application_status.${targetStage}`);

  return i18n.t('applications.stage_change_request.confirm', {
    target: targetStageLabel,
  });
};

export const getUpdateStageErrorMessage = (e: any) => {
  if (e?.data?.error_code === '1_0011') {
    return i18n.t('applications.api.update_stage.not_verified');
  }

  return i18n.t('applications.api.update_stage.default_error');
};

export const getShortlistCandidatesErrorMessage = (e: any) => {
  if (e?.data?.error_code === '1_0011') {
    return i18n.t('applications.api.shortlist.not_verified');
  }

  return i18n.t('applications.api.shortlist.default_error');
};

export const getCreateOfferErrorMessage = (e: any) => {
  if (e?.data?.error_code === '2_0007') {
    return i18n.t('applications.api.create_offer.no_vacancies');
  }

  if (e?.data?.error_code === '2_4002') {
    return i18n.t('applications.api.create_offer.offer_exists');
  }

  return i18n.t('applications.api.create_offer.default_error');
};

export const generateWithdrawApplicationError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === '2_0006') {
      return i18n.t('applications.api.withdraw.application_finalized');
    }
  }

  return i18n.t('applications.api.withdraw.default_error');
};