



import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/system';
import { Tab, Card, Tabs, Grid, Select, Button, MenuItem, Skeleton, TextField, Typography, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { RemoteField, FieldOption, InternalField, IntegrationFieldMapItem } from 'src/services/integration/integrations.types';
import { useGetIntegrationQuery, useGetIntegrationFieldsQuery, useGetIntegrationFieldMapQuery, useUpdateIntegrationFieldMapMutation } from 'src/services/integration/integrations.service';

import Logo from 'src/components/logo';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import ValueMapperModal from 'src/sections/admin/integrations/providers/job-adder/value-mapper-modal';

// ----------------------------------------------------------------------

type JobAdderFieldMapperProps = {
  integrationId: string;
};

const ENTITY_OPTIONS = ["job", "company_profile", "contact", "candidate", "job_application"];
type EntityType = typeof ENTITY_OPTIONS[number];

export default function JobAdderFieldMapper({
  integrationId
}: JobAdderFieldMapperProps) {

  const { enqueueSnackbar } = useSnackbar();
  const authContext = useAuthContext();

  const { integration_id } = useParams();

  const valueMapperControl = useBoolean();

  const [currentFieldMapItem, setSelectedFieldMapItem] = useState<any>(null);

  const [selectedEntity, setEntity] = useState<EntityType>('job');

  const [fieldMap, setFieldMap] = useState<{ internal: InternalField, remote: RemoteField, value_mapping?: { internal: FieldOption, remote: FieldOption }[] }[]>([]);

  const { currentData: integrationDetail, isLoading: isRetrievingIntegration } = useGetIntegrationQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const { currentData: integrationFields, isLoading: isRetrievingFields } = useGetIntegrationFieldsQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const { currentData: integrationFieldMap, isLoading: isRetrievingFieldMap } = useGetIntegrationFieldMapQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  useEffect(() => {
    if (integrationFieldMap) {
      setFieldMap(integrationFieldMap.map((map) => ({ internal: map.internal, remote: map.remote, value_mapping: map.value_mapping })));
    }
  }, [integrationFieldMap]);

  const [updateFieldMap, { isLoading: isUpdating }] = useUpdateIntegrationFieldMapMutation();

  // render settings based on provider type

  const onUpdate = async () => {
    try {
      const fields = fieldMap.map((map) => ({ internal: map.internal.key, remote: map.remote.id as string, value_mapping: map.value_mapping }));

      await updateFieldMap({
        fields,
        integrationId: integration_id as string
      }).unwrap();

      enqueueSnackbar(t('integrations.detail.configuration.field_mapping.api.success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('integrations.detail.configuration.field_mapping.api.default_error'), { variant: 'error' });
    }
  }

  const onSelectTab = (_event: any, tab: EntityType) => {
    setEntity(tab);
  }

  const internalEntityFields = useMemo(() => {
    if (!integrationFields) return [];
    return integrationFields?.internal.filter((field) => field.entity === selectedEntity);
  }, [integrationFields, selectedEntity]);

  const getOptionsForFieldType = (entity: EntityType, field: InternalField) => {
    if (!integrationFields) return [];

    return integrationFields?.remote.filter((remoteField) => remoteField.entity === entity);
  }

  const getSelectedRemoteField = (internalField: InternalField) => {
    if (!integrationFieldMap || !fieldMap) return '--';

    const mapping = fieldMap?.find((map) => map.internal.key === internalField.key);
    return mapping?.remote?.key || '--';
  }

  const onUpdateFieldMap = (fieldKey: string, remoteField: string) => {

    const updatedFieldMap = fieldMap.map((map) => {
      if (map.internal.key === fieldKey) {
        const remoteFieldObj = integrationFields?.remote.find((field) => field.key === remoteField);
        return { ...map, remote: remoteFieldObj };
      }
      return map;
    });

    // what if its a new field?
    if (!updatedFieldMap.find((map) => map.internal.key === fieldKey)) {
      const remoteFieldObj = integrationFields?.remote.find((fieldItem) => fieldItem.key === remoteField);
      const internalFieldObj = integrationFields?.internal.find((fieldItem) => fieldItem.key === fieldKey);

      if (!remoteFieldObj || !internalFieldObj) return;
      updatedFieldMap.push({ internal: internalFieldObj, remote: remoteFieldObj });
    }

    setFieldMap(updatedFieldMap as any);
  }

  const openValueMapper = (internalKey: string) => {

    const selectedFieldMapItem = fieldMap.find((map) => map.internal.key === internalKey);

    setSelectedFieldMapItem(selectedFieldMapItem);
    valueMapperControl.onTrue();
  }

  const closeValueMapper = () => {
    setSelectedFieldMapItem(null);
    valueMapperControl.onFalse();
  }

  const onValueMapUpdated = (fieldMapItem: IntegrationFieldMapItem, valueMapping: { internal: FieldOption, remote: FieldOption }[]) => {
    const updatedFieldMap = fieldMap.map((map) => {
      if (map.internal.key === fieldMapItem.internal.key) {
        return { ...map, value_mapping: valueMapping };
      }
      return map;
    });

    setFieldMap(updatedFieldMap);
  }

  return (
    <Card sx={{ py: 2, px: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">{t('integrations.detail.configuration.field_mapping.title')}</Typography>
        
      </Box>
      {
        (isRetrievingIntegration || isRetrievingFields || isRetrievingFieldMap) ? (
          <Grid container gap={2} sx={{ px: 2, mt: 2 }}>
            <Grid
              xs={6}
            >
              <Skeleton variant="rectangular" height={20} width="50%" />
            </Grid>
            <Grid
              xs={9}
            >
              <Skeleton variant="rectangular" height={20} width="100%" />
            </Grid>
            <Grid
              xs={12}
            >
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          </Grid>
        ) :
          <Stack spacing={2}>
            <Tabs
              value={selectedEntity}
              onChange={onSelectTab}
              sx={{
                px: 2.5,
                mb: { xs: 1, sm: 'unset' }
              }}
            >
              {ENTITY_OPTIONS.map((tab) => (
                <Tab
                  key={tab}
                  iconPosition="end"
                  value={tab}
                  label={
                    <Typography
                      variant="subtitle2"
                      sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                    >
                      {t(`integrations.detail.configuration.field_mapping.entities.${tab}`)}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <Grid container columns={12} spacing={2} sx={{ px: 2, mt: 2 }}>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Logo sx={{ height: '1.5em', objectFit: 'contain' }} disabledLink />
                </Box>
              </Grid>

              <Grid item sm={1} sx={{
                display: { xs: "none", lg: "block" }
              }} />

              <Grid item xs={5} >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Image src={integrationDetail?.provider.logo} sx={{ height: '1em' }} />
                </Box>
              </Grid>

              <Grid item xs={1} />

              {
                internalEntityFields.map((field) => (
                  <>
                    <Grid item xs={5}>
                      <TextField
                        inputProps={{ sx: { px: 2, py: 1, borderRadius: 10 } }}
                        fullWidth
                        variant="filled"
                        value={field.name}
                        disabled
                      />
                    </Grid>
                    <Grid item sm={1} sx={{
                      display: { xs: "none", lg: "block" }
                    }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Iconify icon="material-symbols:sync-alt" color="GrayText" />
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Select
                        fullWidth
                        size="small"
                        disabled={!field.mapping}
                        value={getSelectedRemoteField(field)}
                        onChange={(event) => onUpdateFieldMap(field.key, event.target.value)}
                      >
                        <MenuItem value="--">--</MenuItem>
                        {
                          getOptionsForFieldType(selectedEntity, field).map((option) => (
                            <MenuItem value={option.key}>{option.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </Grid>

                    <Grid item xs={1} >
                      {
                        field.value_mapping && (
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Button
                              variant="text"
                              size="small"
                              sx={{ textWrap: 'nowrap', display: { xs: "none", lg: "block" } }}
                              onClick={() => openValueMapper(field.key)}
                              disabled={!field.value_mapping}
                            >
                              {t('integrations.detail.configuration.field_mapping.configure')}
                            </Button>

                            <IconButton
                              size="small"
                              sx={{
                                display: { xs: "block", lg: "none" }
                              }}
                              onClick={() => openValueMapper(field.key)}
                            >
                              <Iconify icon="pepicons-print:gear" />
                            </IconButton>
                          </Box>
                        )
                      }

                    </Grid>
                  </>
                ))
              }
            </Grid>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <LoadingButton
                type="submit"
                onClick={onUpdate}
                variant="contained"
                loading={isUpdating}
              >
                {t('common.update')}
              </LoadingButton>
            </Box>

            {
              valueMapperControl.value &&
              <ValueMapperModal open={valueMapperControl.value} onClose={closeValueMapper} fieldMapItem={currentFieldMapItem} onUpdate={onValueMapUpdated} />
            }
          </Stack>
      }


    </Card >
  );
}
