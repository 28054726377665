import { ChangeEvent } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Card, { CardProps } from '@mui/material/Card';
import TableContainer from '@mui/material/TableContainer';
import { Tab, Tabs, alpha, Typography, CardContent, useMediaQuery } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { Connection, ConnectionStatus } from 'src/services/connections/connections.types';
import { useUnlinkConnectionMutation } from 'src/services/connections/connections.service';
import { generateUnlinkConnectionErrorMessage } from 'src/services/connections/connection.utils';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import Label, { LabelProps } from 'src/components/label';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import CategoryDisplay from 'src/components/category-display/category-display';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

// eslint-disable-next-line import/no-cycle
import EditConnectionModal from 'src/sections/connections/modal/edit-connection-modal/edit-connection-modal';

import { TenantType } from 'src/types/enums';

import ConnectionsTableToolbar from './connections-table-toolbar';

// ----------------------------------------------------------------------

const TABLE_STATUS_OPTIONS = [ConnectionStatus.CONNECTED, ConnectionStatus.CLOSED];

interface Props extends CardProps {
  tableData: Connection[];
  tableLabels: any;
  selectedTab: ConnectionStatus;
  onSelectTab: (event: any, tab: ConnectionStatus) => void;
  currentPage: PageOptions;
  count: number;
  isLoading?: boolean;
  onChangePage: (_e: any, page: number) => void;
  onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function ConnectionsTable({
  tableData,
  tableLabels,
  selectedTab,
  isLoading = false,
  onSelectTab,
  currentPage,
  onChangePage,
  onRowsPerPageChange,
  count,
  ...other
}: Props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const table = useTable();

  const denseHeight = table.dense ? 56 : 56 + 20;

  const notFound = !tableData?.length;

  return (
    <Card {...other}>
      <ConnectionsTableToolbar onFilters={() => {}} filterQuery="pending" />

      <Tabs
        value={selectedTab}
        onChange={onSelectTab}
        sx={{
          px: 2.5,
          mb: { xs: 1, sm: 'unset' },
          boxShadow: (elTheme) => `inset 0 -2px 0 0 ${alpha(elTheme.palette.grey[500], 0.08)}`,
        }}
      >
        {TABLE_STATUS_OPTIONS.map((tab) => (
          <Tab
            key={tab}
            iconPosition="end"
            value={tab}
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {tab}
              </Typography>
            }
          />
        ))}
      </Tabs>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                {!isMobile && <TableHeadCustom headLabel={tableLabels} />}

                <TableBody>
                  {tableData?.map((row) => (
                    <ConnectionTableRow
                      key={row.id}
                      row={row}
                      disableControls={selectedTab === ConnectionStatus.CLOSED}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length ?? 0)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <TablePaginationCustom
            count={count ?? 0}
            page={currentPage.page}
            rowsPerPage={currentPage.per_page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </>
      )}
    </Card>
  );
}

// ----------------------------------------------------------------------

type AppNewInvoiceConnectionRowProps = {
  row: Connection;
  disableControls: boolean;
};

function ConnectionTableRow({ row, disableControls }: AppNewInvoiceConnectionRowProps) {
  const popover = usePopover();

  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const [unlinkConnection] = useUnlinkConnectionMutation();

  const authContext = useAuthContext();

  const showEditModal = useBoolean();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewDetail = () => {
    router.push(`${paths.dashboard.admin.connection_management.root}/${row.id}`);
  };

  const handleDelete = async () => {
    popover.onClose();
    try {
      await unlinkConnection({
        connection_id: row.id,
        organization_id: authContext.organization.id,
      }).unwrap();

      enqueueSnackbar(t('admin-connections.api.unlink.success'));
    } catch (e) {
      enqueueSnackbar(generateUnlinkConnectionErrorMessage(e), { variant: 'error' });
    }
  };

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Typography
            variant="h6"
            component={Link}
            to={`${paths.dashboard.admin.connection_management.root}/${row.id}`}
            gutterBottom
            color={theme.palette.primary.main}
          >
            {row.connected_organization.name}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {t('admin-connections.table.job_limit', { limit: row.job_limit ?? t('common.none') })}
          </Typography>
        </CardContent>

        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, zIndex: 50 }}
          color={popover.open ? 'primary' : 'default'}
          onClick={popover.onOpen}
          disabled={disableControls}
        >
          <Iconify icon="eva:more-horizontal-fill" />
        </IconButton>

        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 200 }}
        >
          {tenant === TenantType.Client && (
            <MenuItem onClick={showEditModal.onTrue}>
              <Iconify icon="eva:settings-2-outline" />
              {t('admin-connections.table.settings')}
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              popover.onClose();
              viewDetail();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            {t('common.view')}
          </MenuItem>

          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            {t('admin-connections.table.unlink')}
          </MenuItem>
        </CustomPopover>

        <EditConnectionModal
          open={showEditModal.value}
          connection={row}
          onClose={showEditModal.onFalse}
        />
      </Card>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Link
            to={`${paths.dashboard.admin.connection_management.root}/${row.id}`}
            color={theme.palette.primary.main}
          >
            {row.connected_organization.name}
          </Link>
        </TableCell>

        <TableCell>
          <ConnectionStatusLabel status={row.status} />
        </TableCell>

        <TableCell>
          <CategoryDisplay categories={row?.approved_specializations} />
        </TableCell>

        <TableCell>{!row.job_limit ? t('common.unlimited') : row.job_limit}</TableCell>

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
            disabled={disableControls}
          >
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        {tenant === TenantType.Client && (
          <MenuItem onClick={showEditModal.onTrue}>
            <Iconify icon="eva:settings-2-outline" />
            {t('admin-connections.table.settings')}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            popover.onClose();
            viewDetail();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('common.view')}
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('admin-connections.table.unlink')}
        </MenuItem>
      </CustomPopover>

      <EditConnectionModal
        open={showEditModal.value}
        connection={row}
        onClose={showEditModal.onFalse}
      />
    </>
  );
}

export function ConnectionStatusLabel(props: LabelProps & { status: ConnectionStatus }) {
  const { status } = props;
  const warningStatus = [ConnectionStatus.PENDING];
  const errorStatus = [ConnectionStatus.CLOSED];
  const infoStatus = [ConnectionStatus.CONNECTED];

  return (
    <Label
      variant="soft"
      color={
        (warningStatus.includes(status) && 'warning') ||
        (errorStatus.includes(status) && 'error') ||
        (infoStatus.includes(status) && 'info') ||
        'success'
      }
    >
      <Typography
        variant="subtitle2"
        fontSize={11}
        fontWeight="700"
        sx={{ textTransform: 'uppercase' }}
      >
        {status}
      </Typography>
    </Label>
  );
}
