import _ from 'lodash';
import { Fragment, useState, useCallback } from 'react';

import List from '@mui/material/List';
import Stack from '@mui/system/Stack';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Box, Card, Button, Typography, CardContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { hideCandidateInviteActionModal } from 'src/store/slices/jobs/jobsSlice';
import { IncomingCandidateInvite } from 'src/services/candidates/candidates.types';
import {
  useActionOrgCandidateInviteMutation,
  useGetIncomingOrgCandidateInvitesQuery,
} from 'src/services/candidates/candidates.service';

import EmptyContent from 'src/components/empty-content';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

export default function CandidateInvitesActionModal({ open }: Props) {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const tenant = useOrgTenant();

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);
  const [view, setView] = useState<'invite_list' | 'invite_manager'>('invite_list');
  const [currentInvite, setCurrentInvite] = useState<IncomingCandidateInvite | null>(null);

  const { currentData: requestData } = useGetIncomingOrgCandidateInvitesQuery(pageOptions, {
    skip: tenant !== TenantType.Candidate,
  });
  const [actionInvite, { isLoading: isActionLoading }] = useActionOrgCandidateInviteMutation();

  const onClose = useCallback(() => {
    dispatch(hideCandidateInviteActionModal());
    setView('invite_list');
    setCurrentInvite(null);
  }, [dispatch]);

  const changePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const onManageRequest = useCallback((invite: IncomingCandidateInvite) => {
    setCurrentInvite(invite);
    setView('invite_manager');
  }, []);

  const onBack = useCallback(() => {
    setView('invite_list');
    setCurrentInvite(null);
  }, []);

  const renderIncomingButtons = (invite: IncomingCandidateInvite) => (
    <Button
      variant="contained"
      sx={{ mr: 1 }}
      aria-label="manage"
      onClick={() => onManageRequest(invite)}
    >
      {t('common.manage')}
    </Button>
  );

  const renderRequest = (invite: IncomingCandidateInvite, index: number) => (
    <Fragment key={`request-${index}`}>
      <ListItem sx={{ mb: 1 }} secondaryAction={renderIncomingButtons(invite)}>
        <ListItemAvatar>
          <Avatar>{invite.initiator.company_name?.charAt(0)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          secondaryTypographyProps={{
            paddingRight: 14,
          }}
          primary={invite.initiator.company_name}
          secondary={_.capitalize(invite.initiator.company_type)}
        />
      </ListItem>
      <Divider variant="middle" component="li" />
    </Fragment>
  );

  const handleAction = async (invite: IncomingCandidateInvite, action: 'accept' | 'decline') => {
    try {
      await actionInvite({ inviteId: invite.id, action }).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to process invite:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t(`candidates.invites.action_modal.title`, {
          target: t('common.incoming'),
        })}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          {view === 'invite_list' ? (
            <Stack direction="column">
              <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
                {t(`candidates.invites.action_modal.alert`)}
              </Alert>

              {requestData?.count ? (
                <List dense>
                  {requestData?.results?.map((requestObj, requestIndex) =>
                    renderRequest(requestObj, requestIndex)
                  )}
                </List>
              ) : (
                <EmptyContent
                  filled
                  title="No Data"
                  sx={{
                    py: 10,
                  }}
                />
              )}

              <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
                <Pagination
                  page={pageOptions.page || 1}
                  count={Math.ceil((requestData?.count || 1) / pageOptions.per_page)}
                  onChange={(_e, value) => changePage(value)}
                />
              </Stack>
            </Stack>
          ) : (
            currentInvite && (
              <Card sx={{ boxShadow: 'unset' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar sx={{ mr: 2 }}>{currentInvite.initiator.company_name.charAt(0)}</Avatar>
                    <Stack direction="column">
                      <Typography variant="h6">{currentInvite.initiator.company_name}</Typography>
                      <Typography variant="body1" color="text.secondary" textTransform="capitalize">
                        {currentInvite.initiator.company_type}
                      </Typography>
                    </Stack>
                  </Box>
                  <Typography variant="body2" color="text.secondary" textTransform="capitalize">
                    {currentInvite.initiator.company_information}
                  </Typography>
                  <Alert severity="info" sx={{ my: 2 }}>
                    {t('candidates.invites.action_modal.action_alert')}
                  </Alert>
                </CardContent>
              </Card>
            )
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        {view === 'invite_list' && <Button onClick={onClose}>{t('common.close')}</Button>}

        {view === 'invite_manager' && currentInvite && (
          <>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleAction(currentInvite as IncomingCandidateInvite, 'accept')}
              disabled={isActionLoading}
              loading={isActionLoading}
            >
              {t('common.accept')}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={() => handleAction(currentInvite as IncomingCandidateInvite, 'decline')}
              disabled={isActionLoading}
              loading={isActionLoading}
            >
              {t('common.decline')}
            </LoadingButton>
            <Button onClick={onBack}>{t('common.back')}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
