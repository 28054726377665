import * as yup from "yup";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayType = any[] | null | undefined;

yup.addMethod(
  yup.array,
  'unique',
  function (message: string, mapper = (val: any) => val) {
    return this.test(
      'unique',
      message,
      (list: any) => list.length === new Set(list.map(mapper)).size
    )
  }
)

declare module "yup" {
  interface ArraySchema<TIn extends ArrayType, TContext, TDefault = undefined, TFlags extends yup.Flags = ""> {
    unique(
      message: string,
      mapper?: (value: ArraySchema<TIn, TContext, TDefault, TFlags>, index?: number, list?: ArraySchema<TIn, TContext, TDefault, TFlags>[]) => ArraySchema<TIn, TContext, TDefault, TFlags>[]
    ): ArraySchema<TIn, TContext, TDefault, TFlags>
  }
}

export default yup;