
import { Box, Stack } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { AccessTime, LocationOn } from '@mui/icons-material';
import { Card, Chip, Typography, CardContent } from '@mui/material';

import { fCurrency } from 'src/utils/format-number';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { IJob, FeeType, EmploymentType } from 'src/services/jobs/jobs.types';

import TruncatedHTMLRenderer from 'src/components/truncate-text/truncated-html-card';

type Props = {
  job?: IJob;
};

export default function JobCard({ job }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  return (
    <Grid
      container
      spacing={2}
      margin={0}
      p={2}
      width="100%"
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        borderRadius: 2,
      }}
    >
      <Card sx={{ width: '100%', margin: 'auto' }}>
        {
          job &&
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="start">
              <Stack direction="column">
                <Typography variant="h5" component="div" sx={{ textTransform: 'capitalize' }}>
                  {job.title}
                </Typography>
                
                {
                  job.industry &&
                  <Typography variant="caption" color="text.secondary" paragraph>
                    {job.industry ? job.industry.name : '-'}
                  </Typography>
                }

              </Stack>
              <Chip label={`${job.vacancies ?? 1} ${t('jobs.action.info.vacancies')}`} color="primary" size="small" />
            </Box>

            <Grid xs={12}>
              <TruncatedHTMLRenderer html={job.description ?? ''} mode="lines" limit={5} />
            </Grid>

            <Grid container spacing={2} sx={{ my: 2 }}>
              <Grid xs={6} sm={6}>
                <Typography variant="subtitle2">{t('jobs.action.info.min_salary')}</Typography>
                <Typography variant="body2">
                  {job.minimum_salary
                    ? fCurrency(job.minimum_salary as number)
                    : t('common.unknown')}
                </Typography>
              </Grid>
              <Grid xs={6} sm={6}>
                <Typography variant="subtitle2">{t('jobs.action.info.max_salary')}</Typography>
                <Typography variant="body2">
                  {job.maximum_salary
                    ? fCurrency(job.maximum_salary as number)
                    : t('common.unknown')}
                </Typography>
              </Grid>
              <Grid xs={6} sm={6}>
                <Typography variant="subtitle2">{t('jobs.create.form.employment_type.label')}</Typography>
                <Typography variant="body2">
                  {job.employment_type
                    ? t(`enums.employment_type.${job.employment_type}`)
                    : t('common.unknown')}
                </Typography>
              </Grid>
              <Grid xs={6} sm={6}>
                <Typography variant="subtitle2">{t('jobs.create.form.recruitment_type.label')}</Typography>
                <Typography variant="body2">
                  {job.recruitment_type
                    ? t(`enums.recruitment_type.${job.recruitment_type}`)
                    : t('common.unknown')}
                </Typography>
              </Grid>

              {job.employment_type === EmploymentType.PERMANENT ? (
                <Grid xs={6} sm={6}>
                  {
                    job.fee_type === FeeType.FIXED ? (
                      <>
                        <Typography variant="subtitle2">{t('jobs.create.form.fee_value.label')}</Typography>
                        <Typography variant="body2">
                          {job.fee_value
                            ? fCurrency(job.fee_value as number, job.currency ? job.currency.code : 'USD')
                            : t('common.unknown')}
                        </Typography>
                      </>
                    )
                      :
                      (
                        <>
                          <Typography variant="subtitle2">{t('jobs.create.form.fee_percentage.label')}</Typography>
                          <Typography variant="body2">

                            {job.fee_percentage
                              ? `${job.fee_percentage}%`
                              : t('common.unknown')}
                          </Typography>
                        </>
                      )
                  }
                </Grid>
              ) : (
                <Grid xs={6} sm={6}>
                  <Typography variant="subtitle2">{t('jobs.create.form.charge_rate.label')}</Typography>
                  <Typography variant="body2">
                    {job.charge_rate
                      ? fCurrency(job.charge_rate as number, job.currency ? job.currency.code : 'USD')
                      : t('common.unknown')}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Box>
                {
                  job.job_type && (
                    <Chip icon={<AccessTime />} label={t(`enums.job_type.${job.job_type}`)} sx={{ mr: 1 }} />
                  )
                }

                {
                  job.work_style && (
                    <Chip icon={<LocationOn />} label={t(`enums.work_style.${job.work_style}`)} />
                  )
                }
              </Box>
            </Box>
          </CardContent>
        }

      </Card>
    </Grid>
  );
}