import { TenantType } from 'src/types/enums';
import { PayPeriod, ClientPlans, RecruiterPlans } from 'src/types/payment';

export interface Product {
  id: string;
  name: string;
  identifier: RecruiterPlans | ClientPlans;
  tenant_type: TenantType;
  subscription_required: boolean;
  quantity_required: boolean;
  max_quantity: number | null;
  currency: string;
  features: Feature[];
  prices: Price[];
  quantity: number;
  type: ProductType;
}

export enum ProductType {
  Base = 'base_plan',
  Add_on = 'add_on',
}

export interface ChosenProduct {
  identifier: RecruiterPlans | ClientPlans;
  period: PayPeriod;
  quantity: number;
}

export enum FeatureDataType {
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export interface Feature {
  type: string;
  data_type: string;
  value?: number | string | boolean;
  periodic_reset?: boolean;
}

export interface Price {
  amount: number;
  period: PayPeriod;
  tiers: PriceTier[];
}

export interface PriceTier {
  amount: number;
  max_quantity: number;
  min_quantity: number;
}

export interface Subscription {
  id: string;
  status: SubscriptionStatus;
  marked_for_cancellation: boolean;
  trial_ends_at: string;
  ends_at: string;
  billing_period: PayPeriod;
  products: Array<Pick<Product, 'identifier'> & { quantity: number | null }>;
  discount_value?: number;
  discount_type?: "amount" | "percentage";
  data: {
    status: SubscriptionStatus;
  };
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  TRIALING = 'trialing',
  UNPAID = 'unpaid',
}

export enum PaymentMethodType {
  CARD = 'card',
  LINK = 'link'
}

export interface PaymentMethod {
  id: string;
  default: boolean;
  type: string;
  metadata: {
    brand?: string;
    last_4?: string;
    exp_year?: string;
    exp_month?: string;
    email?: string;
  };
}

export interface ActivateSubscriptionProps {
  billing_period: PayPeriod;
  products: ChosenProduct[];
  payment_method_id?: string;
  promo_code?: string;
}

export enum InvoiceStatus {
  Paid = 'paid',
  Closed = 'closed',
  Draft = 'draft',
  Open = 'open',
}

export interface Invoice {
  id: string;
  status: InvoiceStatus;
  billing_reason: string;
  amount_in_cents: number;
  currency: string;
  due_date: string;
  url: string;
  file: InvoiceFile;
  created_at: Date;
}

// TODO: Determine overlap with other file
// types and consider generc solution.
export interface InvoiceFile {
  id: string;
  filename: string;
  public_path: string;
  type: string;
  mime: string;
  created_at: string;
}

export interface FeatureUsage {
  id: string;
  feature: Feature;
  usage: number;
}

export interface OrgFeature {
  feature: Feature;
  value: null | boolean | number;
}
